mm.Station = function ($el) {
  "use strict"

  if (_.isUndefined($el)) {
    return
  }

  var self = mm.EventEmitter()
  var $actions
  self.uuid = $el.attr("data-uuid")

  function init() {
    rebind()
    return self
  }

  function rebind() {
    $actions = $(".action", $el)
    $actions.off("click").on("click", function (e) {
      e.preventDefault()
      var action = $(this).attr("data-action")
      if (action in self) {
        self[action]()
      }
    })
  }

  /**
   * actions
   * @return {[type]} [description]
   */
  self.play = function () {
    if (!self.uuid) {
      return
    }

    $.ajax({
      url: "/stations/" + self.uuid,
      type: "GET",
      success: function (resp) {
        if (!_.has(resp, "list")) {
          return
        }
        return mm.playerProxy.trigger("play", {
          songVersions: resp.list,
          type: "station",
        })
      },
    })
  }

  return init()
}
