mm.DrawerActivationPage = function () {
  var self = mm.DrawerPage($(".mm-drawer-wrap #activation")),
    form = mm.DrawerForm($(".mm-drawer-form", self.$el), {
      url: "/account/activation",
      type: "GET",
      empty: true,
    })

  var superHide = self.hide
  self.hide = function () {
    superHide()
  }

  var superShow = self.show
  self.show = function () {
    superShow()
  }

  return self
}
