mm.DrawerProjectSearchbenchPage = function () {
  "use strict"

  var self = mm.DrawerPage($(".mm-drawer-wrap #project-searchbench")),
    $form = $("form", self.$el),
    $addToSearchOrDirection,
    $searchItemWithDirections,
    $name = $("input[type=text]", $form),
    $description = $('textarea[name="description"]', $form),
    $inputs = $("input", $form),
    $submit = $("button[type=submit]", $form),
    $viewMoreSearches,
    _limit = 10,
    _spinTarg = $("button", $form).parent("div")[0],
    _spinner = new mm.Spinner(),
    _songVersionId,
    _songId

  $form.on("submit", onSubmit)
  $submit.attr("disabled", true)

  function alwaysRun() {
    $name.blur().val("")
    $description.blur().val("")
  }

  function build() {
    $addToSearchOrDirection = $(".add-to", self.$el)
    $searchItemWithDirections = $(".with-directions", self.$el)
    $viewMoreSearches = $("button.view-more-searches", self.$el)

    $addToSearchOrDirection.on("click", function (e) {
      e.preventDefault()
      e.stopPropagation()
      var $projectSearchContainer = $(this).closest(".project-search-item-container")
      var $projectSearchItem = $projectSearchContainer.find("li.project-search-item")
      var directionId = parseInt($(this).data("direction-id"))
      var projectSearchId = parseInt($projectSearchContainer.data("project-search-id"))
      var comparativeIds = $projectSearchContainer.data("project-search-comparative-ids")

      if (isSongVersionPresent($projectSearchItem, comparativeIds)) return false
      _spinner.spin(_spinTarg)
      $inputs.attr("disabled", true)
      $form.addClass("processing")
      $.ajax({
        type: "POST",
        url: "/api/v1/search_rounds/add/" + _songVersionId,
        data: {
          search_round: {
            project_search_id: projectSearchId,
            direction_id: directionId,
          },
        },
        success: function (data) {
          data.success ? onSuccess(data) : onError($projectSearchItem, data.message)
        },
      })
    })

    $name.on("keyup keydown input propertychange", function (e) {
      if (this.value !== "") {
        $submit.removeAttr("disabled")
      } else {
        $submit.attr("disabled", true)
      }
    })

    $searchItemWithDirections.on("click", function (e) {
      e.preventDefault()

      if ($(this).hasClass("expanded")) {
        $(this).removeClass("expanded")
      } else {
        $searchItemWithDirections.removeClass("expanded")
        $(this).addClass("expanded")
      }
    })

    $viewMoreSearches.off("click")
    $viewMoreSearches.click((e) => {
      e.preventDefault()
      _limit += 10
      rerender()
    })

    return self
  }

  function isSongVersionPresent(projectSearchItem, comparativeIds) {
    if (comparativeIds.current_round_sv_ids.includes(_songVersionId)) {
      checkIfSongVersionPresentInCurrentRound(projectSearchItem, comparativeIds)
    } else if (comparativeIds.previous_rounds_sv_ids.includes(_songVersionId)) {
      if (
        !confirm("This song version was added to previous rounds. Would you still like to add it?")
      )
        return true
    } else if (comparativeIds.all_song_ids.includes(_songId)) {
      if (
        !confirm(
          "Another version of this song was added to the search. Would you still like to add it?"
        )
      )
        return true
    } else {
      return false
    }
  }

  function checkIfSongVersionPresentInCurrentRound(projectSearchItem, comparativeIds) {
    if (!comparativeIds.current_round_srsv_users[_songVersionId].includes(mm.user.attributes.id)) {
      if (!confirm("Another user added this song to this round. Would you still like to add it?"))
        return true
    } else {
      onError(projectSearchItem, "You have already selected this song for the round.")
      return true
    }
  }

  function enableFrom() {
    _spinner.stop()
    $inputs.removeAttr("disabled")
    $form.removeClass("processing")
  }

  function handleCloseErrorMessage(el) {
    el.find(".close-error-message").one("click", function (e) {
      e.preventDefault()
      $(this).closest(".search-error-overlay").removeClass("show")
    })
  }

  function onError(projectSearchItem, message) {
    enableFrom()
    handleCloseErrorMessage(projectSearchItem)
    projectSearchItem.find(".message-text").text(message)
    projectSearchItem.find(".search-error-overlay").addClass("show")
  }

  function onFailure(e) {
    console.error(e)
  }

  function onSubmit(e) {
    e.preventDefault()
    if ($name.val() === "") return false
    var data = {
      name: $name.val(),
      description: $description.val(),
    }

    if (_songVersionId) {
      data.song_version = _songVersionId
    }

    $name.off("keyup keydown")
    var projectSearch = mm.ProjectSearch(data)

    _spinner.spin(_spinTarg)
    $inputs.attr("disabled", true)
    $form.addClass("processing")

    mm.user.project_searches.add(projectSearch)
    projectSearch.create().done(onSuccess).fail(onFailure).always(alwaysRun)
  }

  function onSuccess(response) {
    enableFrom()
    $addToSearchOrDirection.off("click")

    // a successful response is an HTML partial as a string
    if (typeof response === "string" || response.success) {
      _songVersionId = undefined
      mm.drawer.delay = false
      mm.drawerProxy.trigger("projectSearches")
    } else {
      var errorsLength = response.errors.length
      for (var i = 0; i < errorsLength; i++) {
        $("<div>" + response.errors[i] + "</div>")
          .appendTo($(".search.errors"))
          .delay(4000)
          .queue(function () {
            $(this).remove()
          })
      }
      mm.drawer.spin(false)
    }
  }

  function rerender() {
    if (!self.$el.hasClass("showing")) _limit = 10
    $.ajax({
      url: "/api/v1/project_searches/user",
      data: { style: "benchlist", limit: _limit },
      success: function (data) {
        var dataHTML = $(data)
        if ($("li", dataHTML).length < _limit) $viewMoreSearches.addClass("hide")
        $(".list", self.$el).html(data)
        build()
      },
    })
  }

  var superShow = self.show
  self.show = function (payload) {
    rerender()
    superShow()
    _songVersionId = payload.id
    _songId = payload.song_id
  }

  return build()
}
