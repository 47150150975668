/**
 * Image object that async loads images into nodes with proper data attributes
 * @public
 * @extends {mm.EventEmitter}
 * @return {Object} Instance of mm.CoverImage
 */
mm.CoverImage = (function (spec) {
  "use strict"

  // merge default with spec
  spec = spec || {}

  // copy properties to self
  var self = _.extend({}, spec, Backbone.Events)

  /**
   * Initializer
   * @return {Object} self
   */
  function init() {
    return self.loadAll()
    return self
  }

  /**
   * Loads any cover-images inside the given elements
   * @param  {Selector} els Elements to search through
   * @return {CoverImage}   Self
   */
  self.load = function (els) {
    _.each(els, function (el, index, list) {
      var $el = $(el),
        loader = mm.AsyncImage({
          el: $el[0],
          url: $el.data("cover-image"),
        })

      loader.loaded = function (img) {
        $el
          .css({
            background: "url(" + img.src + ") no-repeat center center",
            backgroundSize: "cover",
          })
          .addClass("async-loaded")

        $el.attr("data-cover-image-cached", "true")
      }

      loader.load()
    })
    return self
  }

  /**
   * Loads all non-cached cover-images on a page
   * @return {CoverImage}  Self
   */
  self.loadAll = function () {
    var loadees = $("[data-cover-image]").not('[data-cover-image-cached="true"]')
    self.load(loadees)
    return self
  }

  return init()
})()
