mm.DrawerCustomizePage = function () {
  "use strict"

  var self = mm.DrawerPage($(".mm-drawer-wrap #customize")),
    form = mm.DrawerForm($(".mm-drawer-form", self.$el), {
      type: "POST",
      empty: true,
    }),
    _songVersionID

  var superHide = self.hide
  self.hide = function () {
    superHide()
    form.reset()
  }

  var superShow = self.show
  self.show = function (songVersionID) {
    superShow()
    _songVersionID = songVersionID
    form.reset()
    form.options.url = "/song_versions/" + _songVersionID + "/customize"
    form.off("submit").on("submit", form.submit)
    self.resize()
  }

  return self
}
