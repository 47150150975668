/**
 * Collection object
 * @public
 * @extends {mm.EventEmitter}
 * @return {Object} Instance of mm.Collection
 */
mm.Collection = function (spec) {
  // copy properties to self
  var self = _.extend(
    {
      list: {},
    },
    mm.EventEmitter(),
    spec
  )

  /**
   * Add an object to the collection
   * @param  {Object} object Object to be added
   * @return {mm.Collection} Self
   */
  self.add = function (object) {
    self.list[object.id] = object
    object.on("save change update", self.touch)
    object.on("destroy", function (payload) {
      self.remove(this, true)
      self.touch(payload)
    })
    return self
  }

  /**
   * Remove an object from the collection
   * @param  {Object}  object  Object to be removed
   * @param  {Boolean} skip_fetch Flag if the collection should poll the server for new data
   * @return {mm.Collection} Self
   */
  self.remove = function (object, skip_fetch) {
    self.list[object.id].off("save change")
    delete self.list[object.id]
    if (!skip_fetch) self.fetch()
    return self
  }

  /**
   * Find a collection item by a key/value pair
   * @param  {String} key   Key to search on (name, id, etc)
   * @param  {Object} value Value to search on
   * @return {mm.Model}     Matching model
   */
  self.find_by = function (key, value) {
    for (var id in self.list) {
      var item = self.list[id]
      if (item.attributes[key] === value) return item
    }
  }

  /**
   * Find all collection items that match a key/value pair
   * @param  {String} key   Key to search on (name, id, etc)
   * @param  {Object} value Value to search on
   * @return {Array}        Array of matching models
   */
  self.find_all_by = function (key, value) {
    var ret = []
    for (var id in self.list) {
      var item = self.list[id]
      if (item.attributes[key] === value) ret.push(item)
    }
    return ret
  }

  /**
   * Remove all objects from the collection
   * @return {mm.Collection} Self
   */
  self.removeAll = function () {
    for (var id in self.list) {
      self.remove(self.list[id])
    }
    self.touch("")
    return self
  }

  /**
   * Fetch the collection from the server
   * @return {$.Deffered} jQuery deferred object
   */
  self.fetch = function () {
    if (self.url) {
      return $.get(self.url).done(function (data) {
        self.touch(data)
      })
    }
    throw new Error("A URL must be set on the collection in order to fetch.")
  }

  /**
   * Trigger change event
   * @return {mm.Collection} Self
   */
  self.touch = function (payload) {
    if (typeof payload === "undefined") {
      self.fetch()
    } else {
      self.trigger("change", payload)
    }
    return self
  }

  return self
}
