/**
 * Cart, can also be accessed through mm.user.cart
 * @constructor Functional
 * @extends {mm.EventEmitter}
 * @public
 * @return {Object} mm.cart Singleton
 */
mm.cart = (function () {
  var self = mm.EventEmitter()

  /**
   * The order object obtained from the server, populated by self.hydrate
   * @type {Object}
   */
  self.order = {}

  /**
   * Sets self.order with order JSON data from the server
   * @public
   * @param {Function} callback Optional callback
   * @return {Object} mm.cart
   */
  self.hydrate = function (callback) {
    $.get("/cart-status")
      .done(function (data) {
        self.order = data
        mm.facade.on("app:ready", function () {
          mm.user.trigger("update")
        })
        if (typeof mm.user !== "undefined") {
          mm.user.trigger("update")
        }
        if (typeof callback === "function") callback()
        if ($("#mm-drawer-nav nav").hasClass("new-drawer-nav")) {
          if (data.line_items < 1) {
            $("#mm-drawer-nav li.cart").addClass("hidden")
          } else {
            $("#mm-drawer-nav li.cart").removeClass("hidden")
          }
        }
        return self.order
      })
      .fail(function (data) {
        console.error(data)
      })
    return self
  }

  return self.hydrate()
})()
