import $ from "jquery"
window.jQuery = $
window.$ = $

import "jquery-ui/ui/widgets/sortable"

mm.DrawerMixtapesPage = function () {
  "use strict"

  var self = mm.DrawerPage($(".mm-drawer-wrap #mixtapes")),
    $form = $("form", self.$el),
    $links,
    $list,
    $mixtapes,
    $name = $("input[type=text]", $form),
    $description = $('textarea[name="description"]', $form),
    $privacy = $("input#privacy", $form),
    $submit = $("button", $form),
    submitting = false,
    $spinTarg = $("button", $form).parent("div"),
    _spinner = new mm.Spinner()

  function build() {
    $mixtapes = $("li[data-mixtape-id]", self.$el)
    $list = $(".list ul", self.$el)
    $links = $mixtapes.find("a.title")

    $form = $("form", self.$el)

    $links.on("click", function (e) {
      e.preventDefault()
      mm.drawerProxy.trigger("close")
    })

    $mixtapes.each(function () {
      var id = $(this).data("mixtape-id")
      if (typeof mm.user.mixtapes.find_by("id", id) === "undefined") {
        var mixtape = mm.Mixtape()
        mixtape.attributes.id = id
        mm.user.mixtapes.add(mixtape)
      }
    })

    $(".no-mixtapes a").click(function (e) {
      e.preventDefault()
      mm.drawer.trigger("close")
    })

    if ($mixtapes.length > 1) {
      $list.sortable({
        handle: "[data-action=drag]",
        axis: "y",
        start: sortStart,
        stop: sortStop,
        update: sortUpdate,
      })
    } else {
      $mixtapes.addClass("no-sort")
    }

    if ($form.length) {
      buildForm()
    }

    mm.facade.trigger("bypass")
    return self
  }

  function buildForm() {
    $name = $("input[type=text]", $form)
    $description = $('textarea[name="description"]', $form)
    $privacy = $("input#privacy", $form)
    $submit = $("button", $form)
    $form.on("submit", onSubmit)
    $submit.attr("disabled", true)
    $privacy.removeAttr("checked")
    $name.blur().val("")

    $("label .icon.secret", $form).addClass("hidden")

    $privacy.off("change").on("change", function (e) {
      var choice = $(this).is(":checked") ? "public" : "secret"
      $("> label", $form).removeClass("hidden")
      $("> label." + choice, $form).addClass("hidden")

      $("label .icon", $form).removeClass("hidden")
      $("label .icon." + choice, $form).addClass("hidden")
    })

    $name.on("keyup keydown", function (e) {
      if (this.value !== "") {
        $submit.removeAttr("disabled")
      } else {
        $submit.attr("disabled", true)
      }
    })
  }

  function onFailure(e) {
    console.error(e)
  }

  function onSubmit(e) {
    e.preventDefault()
    if (submitting) return
    submitting = true
    mm.drawer.spin(true)
    if ($name.val() === "") return false
    var mixtape,
      data = {
        name: $name.val(),
        description: $description.val(),
        secret: $privacy.is(":checked"),
      }

    $name.off("keyup keydown")
    mixtape = mm.Mixtape(data)
    _spinner.spin($spinTarg[0])
    $("input", $form).attr("disabled", true)
    $form.addClass("processing")
    mm.user.mixtapes.add(mixtape)
    mixtape.create().done(onSuccess).fail(onFailure)
  }

  function onSuccess(data) {
    submitting = false
    _spinner.stop()
    $("input", $form).removeAttr("disabled")
    $form.removeClass("processing")
    rerender("full")
  }

  function rerender(style) {
    style = style || "list"
    $.ajax({
      method: "POST",
      url: "/mixtapes/user",
      data: { style: style },
      success: function (data) {
        mm.drawer.spin(false)
        if (style === "list") {
          $(".list", self.$el).html(data)
        } else if (style === "full") {
          self.$el.find(".root").html(data)
          self.resize()
        }
        build()
      },
    })
  }

  function sortStart(e, ui) {
    ui.item.addClass("dragging")
  }

  function sortStop(e, ui) {
    ui.item.removeClass("dragging")
  }

  function sortUpdate(e, ui) {
    var index = ui.item.index() + 1,
      id = parseInt(ui.item.attr("data-mixtape-id"), 10),
      mixtape = mm.user.mixtapes.find_by("id", id)
    mixtape.update({ position: index }, true)
    ui.item.removeClass("dragging")
    build()
  }

  var superShow = self.show
  self.show = function () {
    rerender("full")
    superShow()
  }

  return build()
}
