/**
 * A base object to extend which is evented and statable.
 * @public
 * @constructor Functional
 * @extends {Backbone.Events}
 * @return {Object} An instance of EventEmitter
 */

mm.EventEmitter = function () {
  "use strict"

  var self = _.extend(
      {
        states: {},
      },
      Backbone.Events
    ),
    _currentState

  /**
   * Checks the current state of this instance
   * @param  {String} state The state to check against
   * @return {Boolean}      The result of comparing states
   */
  self.isState = function (state) {
    return state === _currentState ? true : false
  }

  /**
   * Sets the current state of the instance, and checks which callbacks to call
   * @public
   * @param  {String} state The new state to apply to this instance
   */
  self.setState = function (state) {
    var offCallback,
      onCallback,
      newState = state,
      args = Array.prototype.slice.call(arguments, 1)

    if (newState !== _currentState) {
      onCallback =
        self.states[
          "on" +
            newState.replace(/^\w/, function (s) {
              return s.toUpperCase()
            })
        ]
      if (typeof _currentState !== "undefined") {
        offCallback =
          self.states[
            "off" +
              _currentState.replace(/^\w/, function (s) {
                return s.toUpperCase()
              })
          ]
      }
      _currentState = newState
      if (typeof onCallback !== "undefined") {
        onCallback.apply(self, args)
      }
      if (typeof offCallback !== "undefined") {
        offCallback.apply()
      }
    }
  }

  return self
}
