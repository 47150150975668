mm.desktopPlayer = (function () {
  "use strict"

  var self = mm.BasePlayer($(".player.desktop")),
    $close = $(".close-history", self.$el),
    $closeStations = $(".close-stations", self.$el),
    $historyButton = $(".mm-history", self.$el),
    $historyTarg = $(".mm-song-versions-table-list", self.$el),
    $stationsButton = $(".mm-stations", self.$el),
    $stationsTarg = $(".mm-player-stations-list", self.$el)

  function createSongVersions() {
    var songVersions = $(".song-version-item", $historyTarg)
    songVersions.each(function (i, songVersion) {
      songVersion = mm.SongVersion($(songVersion))
    })
  }

  self.fetchHistory = function () {
    $historyTarg.html("")
    $.get("/song_versions/history", function (data) {
      $historyTarg.html(data)
      createSongVersions()
    })
  }

  var superInjectPlaying = self.injectPlaying
  self.injectPlaying = function (songVersion) {
    $(".mm-connect-right", self.$el).html("")
    superInjectPlaying(songVersion)
    _.defer(self.resize)
  }

  self.resize = function () {
    // Remove oak usage here. Confirm this works.
    if (mm.isMobile) return
    var w = $("#container").outerWidth()
    var offset = self.$scrubber.offset().left
    var actionWidth = self.$actionWrap.outerWidth(true)
    var songDetailsWidth = self.$songVersionDetails.outerWidth(true)
    var songTimeWidth = self.$songVersionTime.outerWidth(true)
    var calculatedWidth = w - offset - actionWidth - songDetailsWidth - 51

    self.$songVersionStream.width(calculatedWidth)
    self.streamWidth = calculatedWidth > 350 ? 350 : calculatedWidth - songTimeWidth
    self.$songVersionWaveform.width(self.streamWidth)
    self.sizeWaveform()
    self.sizeElapsed()
  }

  var superInit = self.init
  self.init = function () {
    superInit()
  }

  return self
})()

mm.facade.on("app:ready", function () {
  // Removed oak usage here. verify this still works correctly.
  if (!mm.isMobile) mm.desktopPlayer.init()
})
