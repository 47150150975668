mm.Song = function ($el) {
  "use strict"

  if ($el === undefined) return

  var self = mm.EventEmitter(),
    $pill,
    $catalogColor,
    $songVersionBadges,
    $internal = ["admin", "business_affairs", "staff", "marketing", "a_and_r"]

  function init() {
    $catalogColor = $el.data("catalog-color")
    $songVersionBadges = $(".song-version-badges", $el)

    if (mm.user.attributes && mm.user.attributes.role) {
      var interal_or_ip =
        mm.user.attributes.role === "international_partner" ||
        $internal.indexOf("mm.user.attributes.role")
          ? true
          : false

      if (interal_or_ip && $catalogColor) {
        applyCatalogColor()
      }
    }

    pillOverlap()
    toggleVersions()
    return self
  }

  function pillOverlap() {
    var $actionsRight = $(".right .action", $el)

    $actionsRight.hover(
      function () {
        var $right = $(this).closest($el).find(".over .right"),
          $connectRight = $(this).closest($el).find(".mm-connect-right"),
          $padding = $(".connect-label.connected", $el).width()

        $right.css("padding-left", $padding + 45)
        $connectRight.css("z-index", 0)
      },
      function () {
        var $right = $(this).closest($el).find(".over .right"),
          $connectRight = $(this).closest($el).find(".mm-connect-right")

        $right.css("padding-left", 10)
        $connectRight.css("z-index", -1)
      }
    )
  }

  function toggleVersions() {
    $pill = $(".default .song-version-badges mark.versions", $el)

    $pill.on("click", function (e) {
      e.preventDefault()
      var $song = $(this).closest(".song-item"),
        $altVersions = $(".alt-versions", $song),
        $songVersionPlaying = $(".playing", $song),
        hideStyle = {
          visibility: "hidden",
          position: "absolute",
          zIndex: "-99999",
        },
        showStyle = {
          visibility: "visible",
          position: "inherit",
          zIndex: 1,
        }

      if ($song.hasClass("expanded")) {
        $altVersions.css(hideStyle)
        $song.removeClass("expanded")
        if ($songVersionPlaying.length !== 0) $song.addClass("playing") // add styling for song when nested song version is playing
      } else {
        if ($songVersionPlaying.length !== 0) $song.removeClass("playing") // remove styling for song when nested song version is playing
        $altVersions.css(showStyle)
        $song.addClass("expanded")
      }
    })
  }

  function hexToOpaqueRgbA(hex) {
    var color
    color = "0x" + hex.replace("#", "")
    return "rgba(" + [(color >> 16) & 255, (color >> 8) & 255, color & 255].join(",") + ",0)"
  }

  function catalogSongVersionBadgeBG(hex) {
    var opaqueColor = hexToOpaqueRgbA(hex)
    return [
      "background-image: -webkit-gradient(linear, 0% 50%, 20 50%, color-stop(0%, " +
        opaqueColor +
        "), color-stop(100%, " +
        hex +
        "))",
      "background-image: -webkit-linear-gradient(to left, " + opaqueColor + ", " + hex + " 20px)",
      "background-image: -moz-linear-gradient(to left, " + opaqueColor + ", " + hex + " 20px)",
      "background-image: -o-linear-gradient(to left, " + opaqueColor + ", " + hex + " 20px)",
      "background-image: linear-gradient(to left, " + opaqueColor + ", " + hex + " 20px)",
    ].join(";")
  }

  function applyCatalogColor() {
    $el.css({ background: $catalogColor })
    $songVersionBadges.attr("style", catalogSongVersionBadgeBG($catalogColor))
  }

  self.destroy = function () {
    $pill.off("click")
  }

  return init()
}
