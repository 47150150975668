;(function (window, Backbone, mm) {
  "use strict"
  // all navigatable routes
  var _routes = {
      "": "home",
      "account/history": "history",
      "account/history/:id": "historyDetail",
      "about/artists": "artists",
      "artists/:slug": "artist",
      collaborations: "collaborations",
      "collaborations/artist-collabs": "artistCollabs",
      "collaborations/artist-residency-house": "artistResidency",
      "collaborations/label-partnerships": "labelPartnerships",
      "collaborations/anthology": "anthology",
      contact: "contact",
      "about/careers": "careers",
      "draft/:id": "prismicDraft",
      preview: "prismicPreview",
      prismic_test: "prismicTest",
      prismic_dev_test: "prismicDevTest",
      prismic_dev_test_home: "prismicDevTestHome",
      favorites: "favorites",
      fervorVinyl: "fervorVinyl",
      help: "help",
      legal: "legal",
      "license-agreement": "licenseAgreement",
      "licensing/custom-request": "customRequest",
      mixtapes: "mixtapes",
      "mixtapes/:id": "mixtape",
      "mixtapes/user": "userMixtapes",
      "project_searches/:id": "projectSearch",
      "project_searches/user": "userProjectSearches",
      "click_order/:id/login": "orderLogin",
      "click_order/:id": "order",
      "order/:encrypted_id/receipt/:type": "receipt",
      "what-we-do": "whatWeDo",
      "about/team": "team",
      "about/purpose": "purpose",
      "about/giving-back": "givingBack",
      "music-licensing": "musicLicensing",
      "music-licensing/podcast-license": "podcastLicense",
      "music-licensing/reel-license": "reelLicense",
      "music-licensing/wedding-license": "weddingLicense",
      browse: songVersionsOrSongVersionsBeta(),
      "browse/:id": "songVersion",
      "browse?*path": songVersionsOrSongVersionsBeta(),
      "browse-beta": "songVersionsBeta",
      "browse-beta?*path": "songVersionsBeta",
      "versions/:id": "song",
      "promos/:promo": "promos",
      studio: "studio",
      work: "ourWork",
      submissions: "submissions",
      "users/:id": "userAccount",
      "*not_found": "not_found",
    },
    _settings = {
      routes: _routes,
      home: function () {
        getPageData("home", "/")
      },
      artists: function () {
        getPageData("artists", "/about/artists")
      },
      history: function () {
        getPageData("history", "/account/history")
      },
      historyDetail: function (id) {
        getPageData("historyDetail", "/account/history/" + id)
      },
      artist: function (slug) {
        getPageData("artist", "/artists/" + slug)
      },
      collaborations: function () {
        getPageData("collaborations", "/collaborations")
      },
      artistCollabs: function () {
        getPageData("artistCollabs", "/collaborations/artist-collabs")
      },
      artistResidency: function () {
        getPageData("artistResidency", "/collaborations/artist-residency-house")
      },
      labelPartnerships: function () {
        getPageData("labelPartnerships", "/collaborations/label-partnerships")
      },
      anthology: function () {
        getPageData("anthology", "/collaborations/anthology")
      },
      contact: function () {
        getPageData("contact", "/contact")
      },
      careers: function () {
        getPageData("careers", "/about/careers")
      },
      prismicDraft: function (id) {
        getPageData("prismicDraft", "/draft/" + id)
      },
      prismicPreview: function () {
        getPageData("prismicPreview", "/preview")
      },
      prismicTest: function () {
        getPageData("prismicTest", "/prismic_test")
      },
      prismicDevTest: function () {
        getPageData("prismicDevTest", "/prismic_dev_test")
      },
      prismicDevTestHome: function () {
        getPageData("prismicDevTestHome", "/prismic_dev_test_home")
      },
      favorites: function () {
        getPageData("favorites", "/favorites")
      },
      fervorVinyl: function () {
        getPageData("fervorVinyl", "/fervormidcenturysounds")
      },
      help: function () {
        getPageData("help", "/help")
      },
      legal: function () {
        getPageData("legal", "/legal")
      },
      licenseAgreement: function () {
        getPageData("licenseAgreement", "/license-agreement")
      },
      customRequest: function () {
        getPageData("customRequest", "/licensing/custom-request")
        mm.drawer.trigger("close")
      },
      mixtapes: function () {
        getPageData("mixtapes", "/mixtapes")
      },
      mixtape: function (id) {
        getPageData("mixtape", "/mixtapes/" + id)
      },
      userMixtapes: function () {
        getPageData("userMixtapes", "/mixtapes/user")
      },
      musicLicensing: function () {
        getPageData("musicLicensing", "/music-licensing")
      },
      podcastLicense: function () {
        getPageData("podcastLicense", "/music-licensing/podcast-license")
      },
      reelLicense: function () {
        getPageData("reelLicense", "/music-licensing/reel-license")
      },
      weddingLicense: function () {
        getPageData("weddingLicense", "/music-licensing/wedding-license")
      },
      projectSearch: function (id) {
        getPageData("projectSearch", "/project_searches/" + id)
      },
      userProjectSearches: function () {
        getPageData("userProjectSearches", "/project_searches/user")
      },
      order: function (id) {
        getPageData("order", "/click_order/" + id)
      },
      orderLogin: function (id) {
        getPageData("order", "/click_order/" + id + "/login")
      },
      ourWork: function () {
        getPageData("ourWork", "/work")
      },
      receipt: function (encrypted_id, type) {
        getPageData("receipt", "/order/" + encrypted_id + "/receipt/" + type)
      },
      whatWeDo: function () {
        getPageData("whatWeDo", "/what-we-do")
      },
      purpose: function () {
        getPageData("purpose", "/about/purpose")
      },
      givingBack: function () {
        getPageData("givingBack", "/about/giving-back")
      },
      submissions: function () {
        getPageData("submissions", "/submissions")
      },
      studio: function () {
        getPageData("studio", "/studio")
      },
      team: function () {
        getPageData("team", "/about/team")
      },
      song: function (id) {
        getPageData("song", "/versions/" + id)
      },
      songVersions: function () {
        getPageData("songVersions", "/browse" + location.search)
      },
      songVersionsBeta: function () {
        getPageData("songVersionsBeta", "/browse" + location.search)
      },
      songVersion: function (id) {
        getPageData("songVersion", "/browse/" + id)
      },
      promos: function (promo) {
        getPageData("promos", "/promos/" + promo)
      },
      userAccount: function (id) {
        getPageData("userAccount", "/users/" + id)
      },
      not_found: function () {
        getPageData("not_found", "/*not_found")
      },
    },
    _currentRoute,
    _spinner = new mm.Spinner({ color: "#FFF" }),
    _silence = mm.hasHistory,
    _hash = location.hash,
    _path = location.pathname,
    $container,
    $content

  /**
   * App initializer, to be called last
   */
  mm.init = function () {
    $container = $("#container")
    $content = $("#content", $container)

    setCSRF()

    // Instantiate the application router
    var _router = Backbone.Router.extend(_settings)
    mm.router = new _router()

    // Bypass all internal routes
    bypassNavigation()

    /**
     * Start Backbone history with pushState enabled, the silent option
     * ensures it is not fired on initial page load, this is different for
     * browsers that do not utilize pushstate, as the hash needs to be read on
     * load for deep linking.
     */
    if (!_silence && !_hash.length && _path.length > 1) {
      _silence = true
    }

    Backbone.history.start({
      pushState: true,
      silent: _silence,
    })

    updateRoute()

    setCurrent()

    bindEvents()

    removeTextNodes()

    mm.resizer.attach(resize)
    mm.resizer.resize()
    handlePortalLink()
    adjustForAnnouncement()

    mm.facade.trigger("app:ready")
  }

  function isInternalUser() {
    return ["admin", "business_affairs", "staff", "marketing", "a_and_r"].includes(
      mm.user.attributes.role
    )
      ? true
      : false
  }

  function songVersionsOrSongVersionsBeta() {
    return "songVersionsBeta"
  }

  function setCSRF() {
    mm.csrf = $('meta[name="csrf-token"]').attr("content")
    $.ajaxSetup({
      beforeSend: function (xhr) {
        xhr.setRequestHeader("X-CSRF-Token", mm.csrf)
      },
    })
  }

  function removeTextNodes() {
    $("body")
      .contents()
      .filter(function () {
        return this.nodeType == Node.TEXT_NODE
      })
      .remove()
  }

  function handlePortalLink() {
    var $link = $(".dashboard-link")
    if ($link.hasClass("available")) {
      if (mm.user.attributes.role === "vendor") {
        $link.text("Artist Portal")
      } else {
        $link.text("Portal")
      }
    }
  }

  /**
   * Handles a backbone page refresh, useful for login/logout
   * @private
   */
  function backboneRefresh() {
    if (Backbone.history.fragment === _currentRoute.fragment) {
      Backbone.history.fragment = null
      mm.router.navigate(_currentRoute.fragment, {
        trigger: true,
        replace: true,
      })
    }
  }

  /**
   * A function to enclose all event binding
   * @private
   */
  function bindEvents() {
    /**
     * functionality to open newsletter in overlay
     */
    $("[data-prevent-default]").on("hover click", function (e) {
      e.preventDefault()
    })

    $("[data-marmoset-newsletter]").on("click", function (e) {
      e.preventDefault()
      if (mm.drawer.pushed) {
        mm.drawerProxy.trigger("close")
      } else {
        mm.drawerProxy.trigger("subscribe")
      }
      return false
    })

    /**
     * footer socials tracking, passthrough anchors
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    $(".social ol li a", "footer[role='main']").on("click", function (e) {
      var $anchor = $(this)
      var $item = $anchor.closest("li")
      var social

      if ($item.hasClass("facebook")) {
        social = "Facebook"
      } else if ($item.hasClass("vimeo")) {
        social = "Vimeo"
      } else if ($item.hasClass("instagram")) {
        social = "Instagram"
      } else if ($item.hasClass("twitter")) {
        social = "Twitter"
      }
    })
  }

  function ajaxURL(url) {
    if (/\?/.test(url)) {
      url += "&_ajax=1"
    } else {
      url += "?_ajax=1"
    }
    return url
  }

  /**
   * Handle all navigation links to use Backbone history router
   * in favor of default functionality
   * @private
   */
  function bypassNavigation() {
    $('a[data-bypass="true"]').on("click", function (e) {
      if (e.metaKey || e.ctrlKey) return
      let href = $(this).attr("href")
      // checking for local links - resolve server-rendering issue
      if (href && href[0] === "/") {
        e.preventDefault()
        mm.router.navigate(href, { trigger: true })
      }
    })

    $("[data-prevent-default]").on("hover click", function (e) {
      e.preventDefault()
    })
  }

  function getPageData(router, url) {
    if (typeof router === "undefined" || typeof url === "undefined") {
      mm.router.navigate("not_found", { trigger: true })
    }

    // Prevent reloading and reinitializing Browse Beta upon history changes within page, i.e. back navigation
    if (
      router === "songVersionsBeta" &&
      (_currentRoute.path === "browse-beta" || _currentRoute.path === "browse")
    ) {
      return
    }

    url = ajaxURL(url)

    showLoading()
    $.get(url)
      .done(function (data) {
        fbq("track", "ViewContent")
        routeSuccess(router, data)
      })
      .fail(function (xhr, status, err) {
        routeFailure(router, xhr, status, err)
      })
      .always(function (data) {
        routeAlways(router, data)
      })
  }

  function routeAlways(router, data) {
    hideLoading()
    setCurrent()
    bypassNavigation()
  }

  function routeFailure(router, xhr, status, err) {
    console.error("Error retreiving page data: " + err)
  }

  function routeSuccess(router, data) {
    var pageScript = router + "Page",
      currentScript = _routes[_currentRoute.path] + "Page"

    // offLoad not being fired for nested routes that do not match
    // a route defined in _routes so this is a map for those cases
    var segmented = _currentRoute.path.split("/")
    if (segmented.length > 1) {
      var route = segmented[0].toLowerCase()
      var inflection

      // segmented route map, mapped to object name of page object
      switch (route) {
        case "artists":
          inflection = "artist"
          break
        case "mixtapes":
          inflection = "mixtape"
          break
        default:
          break
      }

      if (inflection) {
        currentScript = inflection + "Page"
      }
    }

    $content.html(data)

    // Handle page cleanup and page initialization
    if (mm[pageScript] && mm[pageScript].init) {
      if (mm[currentScript] && mm[currentScript].offLoad) {
        mm[currentScript].offLoad()
      }
      mm[pageScript].init()
    }

    // store the current route
    updateRoute()

    // scope the container for css
    $container.attr("data-section", router)

    updatePageTitle($(data).data("title") || $(data)[2]?.dataset?.["title"] || "")

    mm.CoverImage.loadAll()

    $container.scrollTop(0)

    if (mm.isMobile) {
      $("body").scrollTop(0)
    }

    // builds necessary css to transition sync with multiple elements
    mm.drawer._buildCSS()

    adjustForAnnouncement()
  }

  /**
   * Updates the page title
   * @param  {String} page Title for page (Shown after prefix)
   */
  function updatePageTitle(page) {
    var title = "Marmoset"
    if (page != "") {
      title = `${page} | ${title}`
    }
    $("head > title").html(title)
  }

  function resize(current) {
    if (current.w > 767) {
      $(document.body).attr("data-device", "desktop")
      if (mm.isMobile) {
        mm.facade.trigger("desktop")
      }
      mm.isMobile = false
    } else {
      $(document.body).attr("data-device", "mobile")
      removeTextNodes()
      if (!mm.isMobile) {
        mm.facade.trigger("mobile")
      }
      mm.isMobile = true
    }
    mm.drawer.trigger("calc")
    mm.drawer._buildCSS()
    if (!mm.isMobile) mm.desktopPlayer.resize()
  }

  /**
   * Shows the loading spinner
   * @private
   */
  function showLoading() {
    var loading = $("#mm-drawer-nav li.loading")
    if (!$("#mm-drawer-nav nav").hasClass("new-drawer-nav")) {
      var spinner = _spinner.spin()
      $("div", loading).append(spinner.el)
      $(spinner.el).css({ left: 24, top: 24 })
      if (mm.isMobile) {
        mm.spin(true)
      }
    } else {
      $(".m-spinner div", loading).addClass("play")
    }
    loading.removeClass("hidden")
  }

  /**
   * Hides the loading spinner
   * @private
   */
  function hideLoading() {
    var loading = $("#mm-drawer-nav li.loading")
    if (!$("#mm-drawer-nav nav").hasClass("new-drawer-nav")) {
      _spinner.stop()
      if (mm.isMobile) {
        mm.spin(false)
      }
    } else {
      $(".m-spinner div", loading).removeClass("play")
    }
    loading.addClass("hidden")
  }

  /**
   * Replaces current html content with the newly retrieved content from the
   * server response.
   * @private
   * @param  {String} data The server responseText
   */
  function replaceContent(data) {
    $("#content").html(data)
    // handle any bypass links that may have been added to DOM
    bypassNavigation()
  }

  /**
   * Makes the main navigation current based on route fragment
   */
  function setCurrent() {
    var nav = $('nav[role="main"]')
    $("a", nav).removeClass("current")
    $('a[href="/' + _currentRoute.path + '"]').addClass("current")
  }

  function spin(spin) {
    var spinTarg = $(".global-spin")
    //var anchor = $('a.player', spinTarg);

    if (spin) {
      _spinner.spin(spinTarg[0])
      //anchor.css('opacity', 0.1);
    } else {
      _spinner.stop()
      //anchor.css('opacity', 1);
    }
  }

  /**
   * Recusively update TWEEN on rAF
   * @private
   */

  /**
   * Updates _currentRoute object
   * @private
   */
  function updateRoute() {
    _currentRoute = {
      fragment: Backbone.history.fragment,
      path: Backbone.history.location.pathname.replace(/^\//, ""),
    }
  }

  /**
   * Adds or removes classes to adjust header position if Announcement Banner is present at top of page
   */
  function adjustForAnnouncement() {
    if ($("#mm-home-new").length) {
      if ($("#mm-home-new").children().first().hasClass("announcementBanner")) {
        $("header.new-header").addClass("announcement")
        $("#mm-drawer-nav").addClass("announcement")
      }
    } else {
      if ($("header.new-header").hasClass("announcement")) {
        $("header.new-header").removeClass("announcement")
        $("#mm-drawer-nav").removeClass("announcement")
      }
    }
  }

  mm.facade.on("bypass", bypassNavigation)
  mm.facade.on("refresh", backboneRefresh)
  mm.facade.on("route:update", updateRoute)

  mm.spin = spin

  // Last! initialize marmoset
  $(document).ready(mm.init)
})(window, Backbone, mm)
