mm.registerConfirmation = (function () {
  var self = mm.Page()

  self.init = function () {
    self.ps.$el = $("#mm-session.register-confirmation")
    self.ps.form = mm.ProfileForm($("#edit-profile", self.ps.$el), false)
  }

  return self
})()

mm.facade.on("app:ready", function () {
  if ($("#mm-session.register-confirmation").length) {
    mm.registerConfirmation.init()
  }
})
