/**
 * Base asynchronous image
 * @public
 * @param  {Object} spec Object containing initializing props
 * @return {Object}      An instance of mm.AsyncImage
 */
mm.AsyncImage = function (spec) {
  "use strict"

  // define public defaults
  var _defaults = {
    el: undefined,
    url: undefined,
  }

  // merge default with spec
  spec = _.extend(_defaults, spec || {})

  // copy properties to self
  var self = _.extend({}, spec, Backbone.Events),
    _img = new Image()

  /**
   * Initializer
   * @return {Object} self
   */
  function init() {
    _img.onload = function () {
      self.trigger("loaded", _img)
      _img.onload = null
      _img = null
    }
    return self
  }

  /**
   * Simply sets the img src and begins callback sequence
   * @public
   * @return {Object} self
   */
  self.load = function () {
    self.on("loaded", self.loaded)
    _img.src = self.url
  }

  /**
   * Called when the image is loaded, to be overwritten
   * @public
   * @param {Image} img The loaded image
   */
  self.loaded = function (img) {}

  return init()
}
