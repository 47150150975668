/**
 * The drawer page containing any functionality related to adding a license to
 * the cart object. This differs from mm.DrawerCartPage.
 * @public
 * @extends {mm.DrawerPage}
 * @return {Object} mm.DrawerCartbenchPage
 */
mm.DrawerCartbenchPage = function () {
  "use strict"

  var self = mm.DrawerPage($(".mm-drawer-wrap #cartbench")),
    form,
    $licenses,
    $cartbench = $(".mm-drawer-wrap #cartbench"),
    $license_versions,
    _licenseID,
    _licenseIndex,
    _pendingSongVersionID,
    _remove = false,
    songVersionExStatus,
    _pendingSongVersion

  /**
   * Fetch the parsed template from the server showing the proper grouping of
   * licenses and options.
   * @private
   */
  function fetchTemplate(callback) {
    mm.drawer.spin(true)
    $.get("/song_versions/licenses/" + _pendingSongVersionID).done(function (data) {
      self.$el.html(data)
      self.rebuild()
      mm.drawer.spin(false)
      if (typeof callback === "function") callback()
    })
  }

  /**
   * With both the license_id and song_version_id set, submit a POST request to the
   * server and hydrate the mm.cart object with the response.
   * @private
   */
  function processLineItem() {
    var item = {
      line_item: {
        song_version_id: _pendingSongVersionID,
        license_version_id: _licenseID,
      },
    }
    mm.drawer.spin(true)
    if (_remove) {
      mm.drawer.trigger("cart:remove", function () {
        process(item)
      })
    } else {
      process(item)
    }
  }

  function process(item) {
    $.post("/add-to-cart", item)
      .done(function (data) {
        if (data.success) $("#mm-drawer-nav li.cart").removeClass("hidden")
        mm.user.cart.hydrate(function () {
          mm.drawer.spin(false)
          mm.drawer.delay = false
          mm.drawerProxy.trigger("cart")
        })
      })
      .fail(function (data) {
        console.error(data)
      })
  }

  /**
   * Extends base self.hide method to reset our song version and license id's
   * @public
   * @return {Object} mm.drawerCartbenchPage
   */
  var superHide = self.hide
  self.hide = function () {
    _pendingSongVersion = null
    _pendingSongVersionID = null
    _licenseID = null
    _remove = false
    superHide()
    if (!_.isUndefined(form)) {
      form.reset()
    }
    return self
  }

  /**
   * Extends base self.rebuild method, sets and rebinds drawer specific
   * elements.
   * @public
   * @return {Object} mm.drawerCartbenchPage
   */
  var superRebuild = self.rebuild
  self.rebuild = function () {
    superRebuild()
    $licenses = $("[data-license-targ]", self.$el)
    $license_versions = $(".license", $licenses)

    if (_pendingSongVersion) songVersionExStatus = _pendingSongVersion.exclusive

    var localNestLinks = $(".local-nest", self.$el)

    self.$nestLinks.on("click", function (e) {
      e.preventDefault()
      var targ
      targ = $(this).attr("data-license")
      var license = $('[data-license-targ="' + targ + '"]', self.$el)
      $licenses.not(license).removeClass("active")
      license.addClass("active")
    })

    // hack to route custom license button without page reload
    // TODO: look into proper way to do later
    $("#custom-license-req").on("click", function (e) {
      e.preventDefault()
      var href = $(this).attr("href")
      mm.router.navigate(href, { trigger: true })
    })

    localNestLinks.on("click", function (e) {
      e.preventDefault()
      var targ = $(this).attr("href").replace(/^#/, "")
      mm.drawer.delay = false
      mm.drawer.setState(targ)
    })

    $license_versions.on("click", function (e) {
      e.preventDefault()
      var license_name = $(this).data("license-name")
      fbq("track", "AddToCart")
      _licenseID = $(this).attr("data-license-id")
      _licenseIndex = $(this).attr("data-index")
      $license_versions.off("click")

      if (songVersionExStatus === true) {
        exLicenseTarg()
      } else if (license_name === "Podcast License") {
        renderPodcastOption()
      } else {
        processLineItem()
      }
    })

    return self
  }

  function renderPodcastOption() {
    var targ = "podcast"

    var license = $('[data-license-targ="' + targ + '"]', self.$el)
    $licenses.not(license).removeClass("active")
    license.addClass("active")
    $("#podcast-opt-" + _licenseIndex).fadeIn("fast")

    // if back button used for selected podcast option
    $("#mm-drawer .back").on("click", function (e) {
      e.preventDefault()
      if ($('[data-license-targ="podcast"]').hasClass("active")) {
        $('[data-license-targ="podcast"]').removeClass("active")
        $("#podcast-opt-" + _licenseIndex).hide()
        _licenseID = null
        self.rebuild()
      }
    })

    // add podcast license to cart
    var button = $(".podcast-submit")

    button.on("click", function (e) {
      e.preventDefault()
      processLineItem()
    })
  }

  // checks to see if song version is exclusive and changes target to show exclusive deets
  // instead of rendering cart items
  function exLicenseTarg() {
    var targ = "exclusive"

    var license = $('[data-license-targ="' + targ + '"]', self.$el)
    $licenses.not(license).removeClass("active")
    license.addClass("active")
  }

  // user agrees that song version is not exclusive by checking box
  $(".mm-drawer-wrap").on("change", "#excheck", function (e) {
    exEnableContinue()
  })

  function exEnableContinue() {
    var button = $(".ex-button")

    if ($("#excheck").prop("checked") === true) {
      button.removeAttr("disabled")
      button.addClass("available")
    } else {
      button.removeClass("available")
      button.attr("disabled", true)
    }

    button.off("click.ex").on("click.ex", function (e) {
      e.preventDefault()
      processLineItem()
    })
  }

  /**
   * Extends base self.show method, checks for payload (song_version_id) and fetches
   * the template if it exists.
   * @public
   * @return {Object} mm.drawerCartbenchPage
   */
  var superShow = self.show
  self.show = function (payload, remove) {
    _remove = remove
    payload = typeof payload === "string" ? { id: parseInt(payload) } : payload

    if (payload && _.has(payload, "id")) {
      _pendingSongVersion = payload
      _pendingSongVersionID = payload.id
      fetchTemplate(function () {
        if (!payload.has_click_licenses) {
          $cartbench.addClass("left")
        }
      })
    }
    superShow()
    return self
  }

  return self
}
