/**
 * Base page object that contains shared functionality, such as offloading
 * data.
 * @public
 * @extends {mm.EventEmitter}
 * @return {Object} Instance of mm.Page
 */
mm.Page = function () {
  var self = mm.EventEmitter()

  /**
   * This is a page specific (ps) namespace where properties/methods are
   * stored that require 'offloading' when its route is no longer current.
   * This is a general cleanup measure to make sure undefined methods and props
   * are not called upon.
   * @type {Object}
   */
  self.ps = {} // page specific namespace that require offloading

  /**
   * This method loops through the ps object and deletes all keys stored in it.
   * It is called on each routing event. See marmoset_music.js
   * @public
   */
  self.offLoad = function () {
    for (var key in self.ps) {
      delete self.ps[key]
    }
  }

  return self
}
