/**
 * Scripts specific to the drawer cart page
 * @public
 * @return {Object} Instance of mm.DrawerCartPage
 */
mm.DrawerCartPage = function () {
  var self = mm.DrawerPage($(".mm-drawer-wrap #cart")),
    $checkout = $("a.checkout", self.$el),
    $explore = $("a.explore", self.$el),
    $count = $(".count", self.$el),
    $subtotal = $(".totals .subtotal", self.$el),
    $targ = $(".mm-cart-target", self.$el),
    $total = $(".totals .total", self.$el),
    $removes,
    $replacements,
    _pendingRemovalID

  var _linetemplate =
    '<span class="left"><%= label %></span>' + '<span class="right"><%= value %></span>'

  var _template =
    "<% _.each(items, function (item) { %>" +
    "<li>" +
    "<% if (item.song_version) { %>" +
    '<div class="row top">' +
    '<span class="title"><%= item.song_version.title %></span>' +
    '<span class="price"><%= mm.format.numberToCurrency(item.price) %></span>' +
    "</div>" +
    '<div class="row bottom">' +
    '<span class="artist"><%= item.song_version.artist.name %></span>' +
    '<span class="license"><%= item.license_version.name %></span>' +
    "</div>" +
    "<% } else { %>" +
    '<div class="row top">' +
    '<span class="title"><%= item.line_item_type %></span>' +
    '<span class="price"><%= mm.format.numberToCurrency(item.price) %></span>' +
    "</div>" +
    "<% } %>" +
    '<a data-prevent-default data-action="remove" data-id="<%= item.id %>">x</a>' +
    "</li>" +
    "<% }); %>"

  function parseTemplate() {
    var template = _.template(_template)
    var line_items = { items: mm.cart.order.line_items }
    var lineTemplate = _.template(_linetemplate)
    var sub = {
      label: "Subtotal",
      value: mm.format.numberToCurrency(mm.cart.order.price),
    }
    var total = {
      label: "Total",
      value: mm.format.numberToCurrency(mm.cart.order.total),
    }

    if (mm.cart.order.line_items.length > 0) {
      $targ.html(template(line_items))
      $subtotal.html(lineTemplate(sub))
      $total.html(lineTemplate(total))
      $count.html(pluralize(mm.cart.order.line_items.length, "Item"))
      const loginPath = mm.user.isLoggedIn() ? "" : "/login"
      $checkout.attr("href", "/click_order/" + mm.cart.order.id + loginPath)
      $checkout.show()
      $subtotal.show()
      $total.show()
      $count.show()
    } else {
      $targ.html("<p>Your cart is empty!</p>")
      $subtotal.hide()
      $total.hide()
      $count.hide()
      $checkout.hide()
    }

    self.rebuild()
    mm.drawer.spin(false)
  }

  function pluralize(count, str) {
    if (count > 1 || count === 0) {
      return count + " " + str + "s"
    }
    return count + " " + str
  }

  jQuery.easing["easeOutQuad"] = function (x, t, b, c, d) {
    return -c * (t /= d) * (t - 2) + b
  }

  function removeLine(id, that) {
    var _data = {
      id: mm.cart.order.id,
      line_item: id,
    }
    mm.drawer.spin(true)
    $.post("/remove-line", _data)
      .done(function (data) {
        $(that)
          .parent("li")
          .animate(
            {
              opacity: 0,
              height: 0,
              paddingTop: 0,
              paddingBottom: 0,
            },
            {
              duration: 400,
              easing: "easeOutQuad",
              complete: function () {
                $(this).remove()
                mm.cart.hydrate(parseTemplate)
              },
            }
          )
        if (data.line_item_count < 1) {
          $("#mm-drawer-nav li.cart").addClass("hidden")
        }
      })
      .fail(function (data) {
        console.log("fail")
      })
  }

  var superRebuild = self.rebuild
  self.rebuild = function () {
    superRebuild()
    $removes = $("[data-action=remove]", self.$el)
    $replacements = $("[data-action=replace]", self.$el)
    $songTitles = $("span.title", self.$el)

    $removes.on("click", function (e) {
      e.preventDefault()
      e.stopImmediatePropagation()
      var id = $(this).attr("data-id")
      removeLine(id, this)
    })

    $replacements.on("click", function (e) {
      e.preventDefault()
      e.stopImmediatePropagation()
      _pendingRemovalID = $(this).attr("data-id")
      mm.drawer.trigger("cart:add", $(this).attr("data-song-version-id"), true)
    })

    var hasLongTitle = function (displayWidth) {
      return (mm.isMobile && displayWidth > 124) || displayWidth === 170
    }

    $songTitles.hover(
      function (e) {
        var title = $(e.target)
        var displayWidth = title.width()

        if (hasLongTitle(displayWidth)) {
          var actualWidth = title.prop("scrollWidth")
          var transitionWidth = actualWidth - displayWidth

          title.css({
            transition: transitionWidth / 100 + "s linear",
            overflow: "visible",
            transform: "translateX(-" + transitionWidth + "px)",
          })
        }
      },
      function (e) {
        var title = $(e.target)
        var displayWidth = title.width()

        if (hasLongTitle(displayWidth)) {
          var actualWidth = title.prop("scrollWidth")
          var transitionWidth = actualWidth - displayWidth
          var transitionDuration = transitionWidth > 200 ? 0.8 : transitionWidth / 100

          title.css({
            transition: transitionDuration + "s linear",
            overflow: "hidden",
            transform: "none",
          })
        }
      }
    )

    $checkout.on("click", function () {
      fbq("track", "InitiateCheckout")
      mm.drawerProxy.trigger("close")
    })

    $explore.on("click", function (e) {
      e.preventDefault()
      if (window.location.pathname === "/browse") {
        mm.drawerProxy.trigger("close")
      } else {
        $explore.attr({ href: "/browse", "data-bypass": "true" })
        var href = $(this).attr("href")
        mm.router.navigate(href, { trigger: true })
      }
    })
  }

  /**
   * Extends base self.show method, sets template compilation in motion.
   * @public
   * @return {Object} mm.drawerCartPage
   */
  var superShow = self.show
  self.show = function () {
    parseTemplate()
    superShow()
    return self
  }

  self.remove = function (id, callback) {
    $.post("/remove-line", { id: mm.cart.order.id, line_item: id }).done(callback)
  }

  if (mm.drawer) {
    mm.drawer.on("cart:remove", function (callback) {
      if (typeof callback !== "function") {
        callback = function () {}
      }
      self.remove(_pendingRemovalID, callback)
    })
  }

  return self
}
