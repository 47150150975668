mm.DrawerNewsletterPage = function () {
  var self, form

  self = mm.DrawerPage($(".mm-drawer-wrap #subscribe"))
  form = mm.DrawerForm($(".mm-drawer-form", self.$el), {
    url: "/newsletter",
    type: "POST",
  })

  return self
}
