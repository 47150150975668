/**
 * payload expects tp be { type: '{song version|mixtape}', id: id }
 */

mm.DrawerSharePage = function () {
  "use strict"

  var self = mm.DrawerPage($(".mm-drawer-wrap #share")),
    $choices = $("[data-action]", self.$el),
    _event = {},
    _data,
    _form,
    _url

  var actions = {
    /**
     * Fallback email option, opens native mail program
     * @private
     * @param  {Object} e The click event
     */

    email: function (e) {
      _event.label = "email"
      var $a = $("<a/>", {
          href: "mailto:" + recipient + "?" + $.param(params),
        }),
        params = {
          subject: "Marmoset Music",
          body: _url,
        }
      $a.one("click", mm.share.email).trigger("click")
      self.trigger("complete")
    },

    facebook: function (e) {
      _event.label = "facebook"

      FB.ui(
        {
          method: "feed",
          link: _url,
        },
        function (response) {
          if (response && _.has(response, "post_id") && response.post_id) {
            mm.facade.trigger("success", "Facebook Share")
          } else {
            return mm.facade.trigger("error", "Facebook Share")
          }
        }
      )

      self.trigger("complete")
    },

    twitter: function (e) {
      _event.label = "twitter"

      var params = {
        url: _url,
      }

      switch (_data.type) {
        case "mixtape":
          $.extend(params, {
            text: "I made a mixtape for you.",
            hashtags: "musicwithheart",
            via: "marmosetmusic",
          })
          break

        case "song version":
          $.extend(params, {
            text: "This. Song. On Repeat.",
            hashtags: "musicwithheart",
            via: "marmosetmusic",
          })
          break

        default:
          break
      }

      /**
       * build anchor for unobtrusive twitter lib
       */

      var $a = $("<a/>", {
        href: "https://twitter.com/intent/tweet?" + $.param(params),
      })

      /**
       * made the twitter intent handler a public function, we only need to bind it
       * then to trigger it
       */

      $a.one("click", mm.share.twitter).trigger("click")

      self.trigger("complete")
    },

    copy: function (e) {
      _event.label = "copy"
      var $button = $(".copy", self.$el)
      $button.one("click", copyLinkToClipboard(e, _event)).trigger("click")
      alertCopier()
    },
  }

  function fullUrl() {
    return "http://" + [window.document.location.host, _data.segment, _data.id].join("/")
  }

  function copyLinkToClipboard(e, _event) {
    e.preventDefault()
    var category = _event.category

    var tempTextArea = document.createElement("textarea")
    tempTextArea.style.position = "fixed"
    tempTextArea.style.opacity = 0
    tempTextArea.value = _url
    document.body.appendChild(tempTextArea)
    tempTextArea.select()
    document.execCommand("Copy")
    document.body.removeChild(tempTextArea)
  }

  function alertCopier() {
    var $button = $(".copy", self.$el)
    $button.addClass("copied")
  }

  function bindChoices() {
    $choices.off("click").on("click", function (e) {
      e.preventDefault()
      var action = $(this).attr("data-action")
      actions[action] = _.bind(actions[action], this, e)
      actions[action]()
      return false
    })
  }

  /**
   * Builds the share email form for both mixtapes and song versions
   * @private
   */

  function buildForm() {
    _form = mm.DrawerForm($(".mm-drawer-form", self.$el), {
      url: ["", _data.api, _data.id, "share"].join("/"),
      type: "POST",
      empty: true,
    })
  }

  self.on("complete", function () {
    _data = null
    mm.drawerProxy.trigger("close")
  })

  var superHide = self.hide
  self.hide = function () {
    superHide()
    if (_form) {
      _form.reset()
    }
  }

  var superShow = self.show
  self.show = function (payload) {
    bindChoices()
    superShow()
    if (_form) {
      _form.reset()
    }
  }

  self.on("shareData", function (payload) {
    _data = payload

    self.hideNested()

    $(".copy", self.$el).removeClass("copied")

    _event.action = "share"
    _event.value = _data.id

    switch (_data.type) {
      case "mixtape":
        _event.category = "mixtape"
        _event.value = _data.internalID
        self.$el.find(".email.mixtape").show().siblings(".email").hide()
        buildForm()
        break
      case "song version":
        _event.category = "song version"
        self.$el.find(".email.song-version").show().siblings(".email").hide()
        buildForm()
        break
      default:
        self.$el.find(".email.default").show().siblings(".email").hide()
        break
    }

    _url = fullUrl()
  })

  return self
}
