/**
 * User object
 * @public
 * @extends {mm.EventEmitter}
 * @return {Object} Instance of mm.user (Singleton)
 */

mm.user = (function () {
  "use strict"

  // copy properties to self
  var self = _.extend(
      {
        cart: mm.cart,
        mixtapes: new mm.MixtapeCollection(),
        project_searches: new mm.ProjectSearchCollection(),
        processes: [],
        attributes: {},
        hiddenSongs: {},
        favoriteSongVersions: {},
        userSongsLoaded: false,
      },
      mm.EventEmitter()
    ),
    _wipe = false

  /**
   * Initializer
   * @return {Object} self
   */
  function init() {
    var userdata = $('meta[name="marmoset:user"]').attr("content")
    if (userdata !== "") {
      userdata = JSON.parse(userdata)
      setUserData(userdata)
    }
    if (self.userSongsLoaded == false) {
      self.fetchUserSongs()
    }
    mm.facade.on("app:ready", fetchHistory)
    return self
  }

  function setAccountNavLink(data) {
    $("li.account a", "#mm-drawer-nav").attr("href", `/users/${data.id}`)
  }

  function setUserData(data) {
    _.extend(self.attributes, data)
    if (self.attributes.mixtapes) {
      for (var i = 0; i < self.attributes.mixtapes.length; i++) {
        self.mixtapes.add(new mm.Mixtape(self.attributes.mixtapes[i]))
      }
      delete self.attributes.mixtapes
    }
    if (self.attributes.project_searches) {
      for (var i = 0; i < self.attributes.project_searches.length; i++) {
        self.project_searches.add(new mm.ProjectSearch(self.attributes.project_searches[i]))
      }
      delete self.attributes.project_searches
    }
    if (self.attributes.csrfToken) {
      mm.csrf = self.attributes.csrfToken
    }
  }

  self.fetchUserSongs = function () {
    var user_id = null
    if (self.attributes.id) {
      user_id = self.attributes.id
      $.ajax({
        async: false,
        url: "/api/v1/song_versions/get_user_songs",
        data: { id: user_id },
        success: function (data) {
          setUserSongs(data)
        },
      })
    }
  }

  function setUserSongs(data) {
    _.extend(self.attributes, data)
    self.hiddenSongs = data.hiddenSongs
    self.favoriteSongVersions = data.favoriteSongVersions
    self.userSongsLoaded = true
  }

  /**
   * Fetch this user's history from the server and trigger a call to the player
   * @private
   * @param  {Boolean} wipe Flag to wipe the current player queue
   */
  function fetchHistory(wipe) {
    _wipe = typeof wipe === "undefined" ? false : wipe
    $.get("/song_versions/history", { raw: true }).done(function (data) {
      if (Array.isArray(data)) {
        mm.playerProxy.trigger("history", { wipe: _wipe, data: data })
        _wipe = false
      }
    })
  }

  function onOrderLoginPage() {
    return /click_order\/[0-9]*\/login/.test(Backbone.history.fragment)
  }

  /**
   * Determines if the user is logged in
   * @return {Boolean} is the user logged in
   */
  self.isLoggedIn = function () {
    return typeof self.attributes.id !== "undefined"
  }

  /**
   * Logs user in
   * @param  {String}   email    User email
   * @param  {String}   password User password
   * @return {$.Deferred}        jQuery promise
   */
  self.login = function (email, password, order_id) {
    if (self.isLoggedIn()) {
      var deferred = $.Deferred()
      deferred.resolve(self)
      return deferred.promise()
    }

    var data = {
      user: {
        remember_me: 1,
        password: password,
        email: email,
        order_id: order_id,
      },
    }

    return $.ajax({
      url: "/login",
      type: "POST",
      data: data,
      dataType: "json",
      success: function (data) {
        setUserData(data)
        setAccountNavLink(data)
        fetchHistory(true)
        self.fetchUserSongs()
        self.trigger("logged_in")
      },
      error: function (xhr, status, err) {},
    })
  }

  /**
   * Log user out
   * @return {$.Deferred} jQuery promise
   */
  self.logout = function (callback) {
    if (self.isLoggedIn()) {
      return $.ajax({
        url: "/logout",
        type: "GET",
        success: function (data) {
          // clear personal data
          self.attributes = {}
          self.mixtapes.removeAll()
          self.project_searches.removeAll()
          self.hiddenSongs = {}
          self.favoriteSongVersions = {}
          fetchHistory(true)
          self.trigger("logged_out")

          if (data && data.csrfToken) {
            mm.csrf = data.csrfToken
          }
          if (callback) callback()
        },
        error: function (xhr, status, err) {
          console.error("Error logging out user: ", err)
        },
      })
    }
    var deferred = $.Deferred()
    deferred.resolve(self)
    return deferred.promise()
  }

  self.on("logged_in logged_out", function () {
    mm.cart.hydrate(function () {
      var lineCount = self.cart.order.line_items.length
      $("#mm-drawer-nav .cart .count").text(lineCount)

      if (onOrderLoginPage()) {
        mm.router.navigate("/click_order/" + self.cart.order.id, {
          trigger: true,
          replace: true,
        })
      }
    })
  })

  self.on("update", function () {
    var lineCount = self.cart.order.line_items.length
    $("#mm-drawer-nav .cart .count").text(lineCount)
  })

  mm.facade.on("process:success:mixtape", function (data, id) {
    id = parseInt(id, 10)
    self.processes.splice(id, 1)
  })

  return init()
})()
