/**
 * Scripts specific to the drawer login page
 * @public
 * @return {Object} Instance of mm.DrawerLoginPage
 */
mm.DrawerLoginPage = function () {
  var self = mm.DrawerPage($(".mm-drawer-wrap #login")),
    forgotForm = mm.DrawerForm($(".mm-drawer-form.forgot-form", self.$el), {
      url: "/account/reset_password",
      type: "POST",
      empty: false,
    }),
    loginForm = mm.DrawerForm($(".mm-drawer-form.login", self.$el), {}),
    $register = $(".register", self.$el),
    $activation = $(".activation", self.$el)

  self.$message = $(".message", self.$el)

  $register.on("click", function (e) {
    e.preventDefault()
    mm.drawerProxy.trigger("checkNav", "register")
    mm.drawerProxy.trigger("register")
  })

  $activation.on("click", function (e) {
    e.preventDefault()
    mm.drawerProxy.trigger("checkNav", "activation")
    mm.drawerProxy.trigger("activation")
  })

  /*!
   * Begin form method overides
   */

  loginForm.handleErrors = function (response) {
    loginForm.$errors.addClass("display")
    return loginForm
  }

  loginForm.handleSuccess = function (response) {
    loginForm.$errors.removeClass("display")
    loginForm.$success.addClass("display")
    loginForm.$hides.addClass("hide")
    loginForm.$form.addClass("success")
    setTimeout(function () {
      mm.drawerProxy.trigger("close")
    }, 1000)

    return loginForm
  }

  loginForm.reset = function () {
    if (mm.isMobile) {
      loginForm.$inputs.removeClass("invalid")
    } else {
      loginForm.$inputs.removeClass("invalid").first().focus()
    }
    loginForm.$errors.removeClass("display")
    loginForm.$success.removeClass("display")
    loginForm.$hides.removeClass("hide")
    loginForm.$form.removeClass("success")
    loginForm.$form[0].reset()
    return loginForm
  }

  loginForm.submit = function (e) {
    e.preventDefault()
    loginForm.spin(true)
    var email = loginForm.$inputs.filter('[name="email"]').val()
    var pass = loginForm.$inputs.filter('[name="password"]').val()
    var order_id = loginForm.$inputs.filter('[name="order_id"]').val()

    mm.user
      .login(email, pass, order_id)
      .done(loginForm.handleSuccess)
      .fail(loginForm.handleFail)
      .always(loginForm.complete)

    return loginForm
  }

  // bind submit _after_ redefining submit handler
  loginForm.$form.off("submit").on("submit", loginForm.submit)

  var superHide = self.hide
  self.hide = function () {
    superHide()
    loginForm.reset()
    forgotForm.reset()
    self.notifyReset()
  }

  var superShow = self.show
  self.show = function () {
    superShow()
    loginForm.reset()
    forgotForm.reset()
  }

  /**
   * display a message in the login form
   * @param  {[type]} msg [description]
   * @return {[type]}     [description]
   */
  self.notify = function (msg) {
    self.$message.html(msg || "").addClass("display")
  }

  self.notifyReset = function () {
    self.$message.empty().removeClass("display")
  }

  return self
}
