;(function () {
  /**
   * A throttled class for binding logic to the resize event of a given DOM
   * element
   * @constructor
   * @singleton
   * @param= {Element} el The DOM element to listen on
   */
  function Resizer(el) {
    if (typeof Resizer.instance === "object") {
      return Resizer.instance
    }

    var self = this
    this._el = el || window
    this._calls = []
    this.lastKnown = {
      w: this._el.innerWidth,
      h: this._el.innerHeight,
    }
    this._ticking = false

    this.resize = Resizer.prototype.resize.bind(this)
    this._update = Resizer.prototype._update.bind(this)

    this._el.addEventListener("resize", this.resize, false)

    if (window.matchMedia("(pointer: coarse)").matches) {
      var orientationInt
      this._el.addEventListener("orientationchange", function () {
        clearTimeout(orientationInt)
        var count = 0
        orientationInt = setInterval(function () {
          self.resize()
          count += 1
          if (count === 6) {
            clearTimeout(orientationInt)
          }
        }, 500)
        self.resize()
      })
    }

    Resizer.instance = this
  }

  /**
   * Debouncer for resize event, uses requestAnimationFrame to access frame for
   * update
   * @private
   * @return {Boolean} Toggles this._ticking to true
   */
  Resizer.prototype._request_tick = function () {
    if (!this._ticking) {
      window.requestAnimationFrame(this._update)
    }
    return (this._ticking = true)
  }

  /**
   * Internal update method; loops through all attached functions and executes
   * them on the throttled resize event.
   * @this {Resizer}
   * @private
   */
  Resizer.prototype._update = function () {
    var i = 0,
      current = this._lastKnown,
      len = this._calls.length
    this._ticking = false
    for (i; i < len; i += 1) {
      this._calls[i](current)
    }
  }

  /**
   * Registers a function to be called on the throttled resize event to the
   * this._calls array
   * @public
   * @param  {Function} func The function to be added
   * @return {Resizer}
   */
  Resizer.prototype.attach = function (func) {
    if (typeof func !== "function") {
      throw {
        name: "Invalid Argument",
        message: "Resizer's attach method requires a function argument.",
      }
    }
    if (this.exists(func)) {
      return false
    }
    this._calls.push(func)
    return this
  }

  /**
   * Removes an attached function from the this._calls array
   * @public
   * @param  {Function} func The function to be removed
   * @return {Boolean}       Successful/Failure to remove
   */
  Resizer.prototype.detach = function (func) {
    if (typeof func !== "function") {
      throw {
        name: "Invalid Argument",
        message: "Resizer's detach method requires a function argument.",
      }
    }
    var index = this._calls.indexOf(func)
    if (index < 0) {
      return false
    }
    this._calls.splice(index, 1)
    return true
  }

  /**
   * Checks to see if a function is attached to the resizer
   * @public
   * @param  {Function} func The function to check for
   * @return {Boolean}       If if was found or not
   */
  Resizer.prototype.exists = function (func) {
    if (typeof func !== "function") {
      throw {
        name: "Invalid Argument",
        message: "Resizer's exists method requires a function argument.",
      }
    }
    return this._calls.indexOf(func) >= 0
  }

  /**
   * The resize event handler
   * @public
   * @this {Resizer}
   */
  Resizer.prototype.resize = function () {
    var self = this
    this._lastKnown = {
      w: self._el.innerWidth,
      h: self._el.innerHeight,
    }
    this._request_tick()
  }

  mm.resizer = new Resizer()
})()
