/**
 * ProjectSearch object
 * @public
 * @extends {mm.Model}
 * @return {Object} Instance of mm.ProjectSearch
 */
mm.ProjectSearch = function (spec) {
  return mm.Model(spec, {
    name: "project_search",
    create_url: "/api/v1/project_searches/create",
    update_url: "/api/v1/project_searches/sort",
  })
}
