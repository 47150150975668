mm.HoverConnect = function ($el, context) {
  "use strict"

  if ($el === undefined || context === undefined) return

  var self = {},
    _connect = $el.data("connect"),
    _text = $el.data("connect-text"),
    $targ = $(".mm-connect-" + _connect, context),
    $temp = $("<div/>").addClass("connect-label").html(_text),
    _log = false

  function init() {
    if (_log) console.log("Hover Connect | mm.HoverConnect | self.init()")
    $targ.append($temp)
    return self
  }

  $el.on("mouseover", function (e) {
    $temp.addClass("connected")
    toggleHiddenRow(e, true)
  })

  $el.on("mouseleave", function (e) {
    $temp.removeClass("connected")
    toggleHiddenRow(e, false)
  })

  function toggleHiddenRow(e, active) {
    var buttonEl = $(e.target.firstElementChild)

    if (buttonEl && buttonEl.text() === "Hide") {
      var songRowLeft = buttonEl.parents(".right").prev()
      var actionButtons = buttonEl.closest(".show-hide").siblings(".action")

      if (active) {
        actionButtons.addClass("disabled")
        songRowLeft.addClass("disabled")
      } else {
        actionButtons.removeClass("disabled")
        songRowLeft.removeClass("disabled")
      }
    }
  }

  self.update = function () {
    _text = $el.attr("data-connect-text")
    $temp.html(_text)
  }

  self.destroy = function () {
    $el.off("mouseover mouseleave")
    _connect = null
    $targ = null
  }

  return init()
}
