;(function () {
  /**
   * A throttled class for binding logic to the scroll event on a given DOM
   * element
   * @constructor
   * @singleton
   * @param= {Element} el The DOM element to listen on
   */
  function Scroller(el) {
    if (typeof Scroller.instance === "object") {
      return Scroller.instance
    }

    this._el = el || window
    this._calls = []
    this._lastKnown = this._el.scrollTop
    this._ticking = false

    // Bind certain methods to Scroller
    this.scroll = Scroller.prototype.scroll.bind(this)
    this._update = Scroller.prototype._update.bind(this)

    this._el.addEventListener("scroll", this.scroll, false)

    Scroller.instance = this
  }

  /**
   * Debouncer for scroll event, uses requestAnimationFrame to access frame for
   * update
   * @private
   * @return {Boolean} Toggles this._ticking to true
   */
  Scroller.prototype._request_tick = function () {
    if (!this._ticking) {
      window.requestAnimationFrame(this._update)
    }
    return (this._ticking = true)
  }

  /**
   * Internal update method; loops through all attached functions and executes
   * them on the throttled scroll event.
   * @this {Scroller}
   * @private
   */
  Scroller.prototype._update = function () {
    var i = 0,
      current = this._lastKnown,
      len = this._calls.length
    this._ticking = false
    for (i; i < len; i += 1) {
      this._calls[i](current)
    }
  }

  /**
   * Registers a function to be called on the throttled scroll event to the
   * this._calls array
   * @public
   * @param  {Function} func The function to be added
   * @return {Scroller}
   */
  Scroller.prototype.attach = function (func) {
    if (typeof func !== "function") {
      throw {
        name: "Invalid Argument",
        message: "Scroller's attach method requires a function argument.",
      }
    }
    if (this.exists(func)) {
      return false
    }
    this._calls.push(func)
    return this
  }

  /**
   * Removes an attached function from the this._calls array
   * @public
   * @param  {Function} func The function to be removed
   * @return {Boolean}       Successful/Failure to remove
   */
  Scroller.prototype.detach = function (func) {
    if (typeof func !== "function") {
      throw {
        name: "Invalid Argument",
        message: "Scroller's detach method requires a function argument.",
      }
    }
    var index = this._calls.indexOf(func)
    if (index < 0) {
      return false
    }
    this._calls.splice(index, 1)
    return true
  }

  /**
   * Checks to see if a function is attached to the scroller
   * @public
   * @param  {Function} func The function to check for
   * @return {Boolean}       If it was found or not
   */
  Scroller.prototype.exists = function (func) {
    if (typeof func !== "function") {
      throw {
        name: "Invalid Argument",
        message: "Scroller's exists method requires a function argument.",
      }
    }
    return this._calls.indexOf(func) >= 0
  }

  /**
   * The scroll event handler
   * @public
   * @this {Scroller}
   */
  Scroller.prototype.scroll = function () {
    this._lastKnown = this._el.scrollTop
    this._request_tick()
  }

  // The public instance of Scroller
  mm.scroller = new Scroller(document.getElementById("container"))
})()
