mm.DrawerMixbenchPage = function () {
  "use strict"

  var self = mm.DrawerPage($(".mm-drawer-wrap #mixbench")),
    $form = $("form", self.$el),
    $mixtapes,
    $name = $("input[type=text]", $form),
    $description = $('textarea[name="description"]', $form),
    $inputs = $("input", $form),
    $privacy = $("input#privacy", $form),
    $submit = $("button[type=submit]", $form),
    _spinTarg = $("button", $form).parent("div")[0],
    _spinner = new mm.Spinner(),
    _songVersionID

  $form.on("submit", onSubmit)
  $submit.attr("disabled", true)

  function alwaysRun() {
    $privacy.prop("checked", false)
    $("label .icon.secret", $form).addClass("hidden")
    $name.blur().val("")
  }

  function build() {
    $mixtapes = $("li[data-mixtape-id]", self.$el)
    $privacy.prop("checked", false)
    $("label .icon.secret", $form).addClass("hidden")

    $privacy.off("change").on("change", function (e) {
      var choice = $(this).is(":checked") ? "public" : "secret"
      $("> label", $form).removeClass("hidden")
      $("> label." + choice, $form).addClass("hidden")

      $("label .icon", $form).removeClass("hidden")
      $("label .icon." + choice, $form).addClass("hidden")
    })

    $mixtapes.on("click", function (e) {
      e.preventDefault()
      var mixtapeID = $(this).attr("data-mixtape-id")
      _spinner.spin(_spinTarg)
      $inputs.attr("disabled", true)
      $form.addClass("processing")
      $.ajax({
        url: "/mixtapes/" + mixtapeID + "/add/" + _songVersionID,
        success: onSuccess,
      })
    })

    $name.on("keyup keydown", function (e) {
      if (this.value !== "") {
        $submit.removeAttr("disabled")
      } else {
        $submit.attr("disabled", true)
      }
    })

    return self
  }

  function onFailure(e) {
    console.error(e)
  }

  function onSubmit(e) {
    e.preventDefault()
    if ($name.val() === "") return false
    var mixtape,
      data = {
        name: $name.val(),
        description: $description.val(),
        secret: $privacy.is(":checked"),
      }
    if (_songVersionID) {
      data.song_version = _songVersionID
    }
    $name.off("keyup keydown")
    mixtape = mm.Mixtape(data)

    _spinner.spin(_spinTarg)
    $inputs.attr("disabled", true)
    $form.addClass("processing")

    mm.user.mixtapes.add(mixtape)
    mixtape.create().done(onSuccess).fail(onFailure).always(alwaysRun)
  }

  function onSuccess(data) {
    _spinner.stop()
    $inputs.removeAttr("disabled")
    $form.removeClass("processing")
    _songVersionID = undefined
    $mixtapes.off("click")
    mm.drawer.delay = false
    mm.drawerProxy.trigger("mixtapes")
  }

  function rerender() {
    $.ajax({
      method: "POST",
      url: "/mixtapes/user",
      data: { style: "benchlist" },
      success: function (data) {
        $(".list", self.$el).html(data)
        build()
      },
    })
  }

  var superShow = self.show
  self.show = function (payload) {
    rerender()
    superShow()
    _songVersionID = payload
  }

  return build()
}
