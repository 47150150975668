/**
 * Base drawer page object that contains shared functionality for drawer views.
 * @public
 * @constructor Functional
 * @extends {mm.EventEmitter}
 * @return {Object} Instance of mm.DrawerPage
 */
mm.DrawerPage = function ($el) {
  var self = mm.EventEmitter()

  /**
   * Hides the nested view, sets nested prop to false
   * @private
   * @param  {Object} e Click event object
   */
  self.hideNested = function (e) {
    if (e) e.preventDefault()
    $el.removeClass("left")
    self.$back.removeClass("available")
    self.nested = false
    self.resize()
    resetForms($el)
  }

  /**
   * Called on transitionend, removes transitionend event binding
   * @private
   */
  function onShowEnd() {
    $el[0].removeEventListener("transitionend", onShowEnd)
    self.resize()
  }

  /**
   * resets forms and submit button text, requires $el for scope
   * @param  {[type]} $el [description]
   * @return {[type]}     [description]
   */
  function resetForms($el) {
    var $forms = $el.find(".nested form, .root form")
    if ($forms.length > 0) {
      $forms.each(function () {
        var $form = $(this)
        $form.get(0).reset()
        $form.find(".error, .errors").hide()
        $form.find("[data-default-text]").each(function () {
          var $button = $(this)
          $button.html($button.data("default-text"))
        })
      })
    }
  }

  /**
   * This drawer page's context
   * @type {Element} Jquery Object
   */
  self.$el = $el

  /**
   * Drawer back button
   * @type {Element} Jquery Object
   */
  self.$back = $("#mm-drawer .back")

  /**
   * Internal links that route to internal (nested) views
   * @type {Element} Jquery Object, collection
   */
  self.$nestLinks = $(".nest", $el)

  /**
   * The unique identifier of this drawer page
   * @type {String}
   */
  self.id = self.$el.attr("id")

  /**
   * Flag to check if current Drawer Page is nested
   * @type {Boolean}
   */
  self.nested = false

  /**
   * Called on transitionend, removes some bindings/classes and checks to see
   * if we are to reset the nested view back to its original position.
   * @public
   * @return {Object} This instance of mm.DrawerPage
   */
  self.cleanup = function () {
    $el.removeClass("hiding")
    self.$nestLinks.off("click")
    if (self.nested) {
      self.hideNested()
    }
    $el[0].removeEventListener("transitionend", self.cleanup)
    return self
  }

  /**
   * Hides the page and resets the nested views
   * @public
   * @return {Object} This instance of mm.DrawerPage
   */
  self.hide = function () {
    $el[0].addEventListener("transitionend", self.cleanup)
    $el.addClass("hiding").removeClass("showing share-mixtape")
    return self
  }

  /**
   * Resizes the current drawer based on content
   */
  self.resize = function () {
    var h

    if ($el.hasClass("left")) {
      h = $(".nested", $el).first().outerHeight()
    } else {
      h = $(".root", $el).first().outerHeight()
    }

    $el.height(h).siblings().height("")
  }

  /**
   * Resets key variables and bindings, executed everytime self.show is called
   * @public
   * @return {Object} This instance of mm.DrawerPage
   */
  self.rebuild = function () {
    self.$back.on("click", self.hideNested)
    self.$nestLinks = $(".nest", $el)
    self.$nestLinks.on("click", function (e) {
      self.showNested(e, this)
    })
    self.resize()
    return self
  }

  /**
   * Show the page
   * @public
   * @return {Object} mm.DrawerPage
   */
  self.show = function () {
    self.rebuild()
    $el[0].addEventListener("transitionend", onShowEnd)
    $el.addClass("showing")
    self.resize()

    if (window.isFF) {
      $(".nested", $el).each(function () {
        inlineLeft(this)
      })
    }

    return self
  }

  /**
   * Shows a nested view (slides container over)
   * @public
   * @param  {Event}  e    Click event object
   * @param  {Object} that thisArg
   * @return {Object}      This instance of mm.DrawerPage
   */
  self.showNested = function (e, that) {
    e.preventDefault()
    self.$back.addClass("available")
    $el.addClass("left")
    self.nested = true
    resetForms($el)
    self.resize()
    $(".nested", $el)[0].addEventListener("transitionend", onShowEnd)
    return self
  }

  function inlineLeft(el) {
    var left = $(el).css("left")
    $(el).css({
      left: 0,
      transition: "none",
    })
    _.defer(function () {
      $(el).css({ left: left })
      _.defer(function () {
        $(el).css({ left: "", transition: "" })
      })
    })
  }

  mm.drawer.on("resize:" + self.id, self.resize)

  return self
}
