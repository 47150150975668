/**
 * Mixtape object
 * @public
 * @extends {mm.Model}
 * @return {Object} Instance of mm.Mixtape
 */
mm.Mixtape = function (spec) {
  return mm.Model(spec, {
    name: "mixtape",
    create_url: "/mixtapes",
    update_url: "/mixtapes",
  })
}
