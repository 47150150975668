mm.mobilePlayer = (function () {
  "use strict"

  var self = mm.BasePlayer($(".player.mobile"))

  var superInit = self.init
  self.init = function () {
    superInit()
    self.streamWidth = self.$songVersionStream.width()
    self.sizeElapsed()

    $("nav[role=main] .player-toggle").off("click").on("click", togglePlay)
  }

  function togglePlay() {
    if (self.isState("playing")) {
      self.pause()
    } else if (self.isState("paused")) {
      self.play()
    }
  }

  self._onCanplay = function (e) {
    mm.spin(false)
    self.$playButton.removeClass("spinning")
    self.audio.play()
  }

  var superOnPlaying = self.states.onPlaying
  self.states.onPlaying = function () {
    superOnPlaying()
    self.$el.add(self.$el.parents("li.player")).addClass("expanded")
    $("nav[role=main] .hamburger").addClass("music-playing")
    $("nav[role=main]").removeClass("paused")
    $("nav[role=main]").addClass("playing")
  }

  var superOnPaused = self.states.onPaused
  self.states.onPaused = function () {
    superOnPaused()
    $("nav[role=main] .hamburger").removeClass("music-playing")
    $("nav[role=main]").removeClass("playing")
    $("nav[role=main]").addClass("paused")
  }

  var superOnIdle = self.states.onIdle
  self.states.onIdle = function () {
    superOnIdle()
    $("nav[role=main]").removeClass("playing paused")
  }

  return self
})()

mm.facade.on("app:ready", function () {
  if (mm.isMobile) mm.mobilePlayer.init()
})
