import $ from "jquery"
window.jQuery = $
window.$ = $

import "jquery-ui/ui/widgets/sortable"

mm.DrawerProjectSearchesPage = function () {
  "use strict"

  var self = mm.DrawerPage($(".mm-drawer-wrap #project-searches")),
    $form,
    $links,
    $list,
    $remove,
    $projectSearches,
    $searchSearchBar,
    $noResults,
    $name = $("input[type=text]", $form),
    $description = $('textarea[name="description"]', $form),
    $submit = $("button", $form),
    $viewMoreSearches,
    submitting = false,
    $spinTarg = $("button", $form).parent("div"),
    _limit = 10,
    _spinner = new mm.Spinner()

  function build() {
    $projectSearches = $("li[data-project-search-id]", self.$el)
    $links = $projectSearches.find("a.title")
    $list = $(".list ul", self.$el)
    $remove = $(".remove", self.$el)
    $form = $("form#create-project-search", self.$el)
    $viewMoreSearches = $("button.view-more-searches", self.$el)

    $links.on("click", function (e) {
      e.preventDefault()
      mm.drawerProxy.trigger("close")
    })

    $projectSearches.each(function () {
      var id = $(this).data("project-search-id")
      if (typeof mm.user.project_searches.find_by("id", id) === "undefined") {
        var projectSearch = mm.ProjectSearch()
        projectSearch.attributes.id = id
        mm.user.project_searches.add(projectSearch)
      }
    })

    $(".no-project-searches a").click(function (e) {
      e.preventDefault()
      mm.drawer.trigger("close")
    })

    $remove.on("click", function (e) {
      e.preventDefault()
      e.stopPropagation()
      var searchItem = $(this).closest("li.button-box")
      var id = searchItem.data("project-search-id")
      searchItem.hide()
      removeProjectSearch(id)
    })

    if ($projectSearches.length > 1) {
      $list.sortable({
        handle: "[data-action=drag]",
        axis: "y",
        start: sortStart,
        stop: sortStop,
        update: sortUpdate,
      })
    } else {
      $projectSearches.addClass("no-sort")
    }

    if ($form.length) {
      buildForm()
      buildSearchBar()
    }

    $viewMoreSearches.off("click")
    $viewMoreSearches.click((e) => {
      e.preventDefault()
      _limit += 10
      rerender()
    })

    mm.facade.trigger("bypass")
    return self
  }

  function buildForm() {
    $name = $("input[type=text]", $form)
    $description = $('textarea[name="description"]', $form)
    $submit = $("button", $form)
    $form.on("submit", onSubmit)
    $submit.attr("disabled", true)
    $name.blur().val("")
    $name.on("keyup keydown input propertychange", function () {
      if (this.value !== "") {
        $submit.removeAttr("disabled")
      } else {
        $submit.attr("disabled", true)
      }
    })
  }

  function buildSearchBar() {
    $searchSearchBar = $("input.search-search-bar", self.$el)
    $noResults = $("div.no-results", self.$el)

    $searchSearchBar.on("keyup keydown", function () {
      if (this.value === "") $noResults.removeClass("show")
    })

    var autocomplete = function () {
      $searchSearchBar
        .autocomplete({
          minLength: 1,
          delay: 600,
          source: function (request, response) {
            return $.ajax({
              url: "/api/v1/project_searches/autocomplete",
              dataType: "json",
              data: {
                term: request.term,
              },
              success: function (data) {
                $noResults.width($searchSearchBar.width()).removeClass("show")
                if (data.length > 0) {
                  return response(data)
                } else {
                  $noResults.addClass("show")
                }
              },
            })
          },
          select: function (event, ui) {
            event.preventDefault()
            $(this).val(ui.item.label)
            addToDrawer(ui.item.value)
          },
        })
        .data("ui-autocomplete")._renderItem = function (ul, item) {
        ul.addClass("project-search-select-options")

        return $("<li></li>")
          .data("item.autocomplete", item)
          .append("<a>" + item.label + "</a>")
          .attr("title", item.label.length > 30 ? item.label : "")
          .appendTo(ul)
      }
    }

    autocomplete()
  }

  function onFailure(e) {
    console.error(e)
  }

  function addToDrawer(id) {
    $.ajax({
      url: "/api/v1/project_searches/add_to_drawer",
      type: "POST",
      data: { id: id },
      success: function () {
        $searchSearchBar.val("")
        rerender()
      },
    })
  }

  function removeProjectSearch(id) {
    $.ajax({
      url: "/api/v1/project_searches/remove_from_drawer",
      type: "POST",
      data: { id: id },
      success: function (data) {
        return data
      },
    })
  }

  function onSubmit(e) {
    e.preventDefault()
    if (submitting) return
    submitting = true
    mm.drawer.spin(true)
    if ($name.val() === "") return false

    var data = {
      name: $name.val(),
      description: $description.val(),
    }

    $name.off("keyup keydown")
    var projectSearch = mm.ProjectSearch(data)
    _spinner.spin($spinTarg[0])
    $("input", $form).attr("disabled", true)
    $form.addClass("processing")
    mm.user.project_searches.add(projectSearch)
    projectSearch.create().done(onSuccess).fail(onFailure)
  }

  function onSuccess(response) {
    submitting = false
    _spinner.stop()
    $("input", $form).removeAttr("disabled")
    $form.removeClass("processing")

    // a successful response is an HTML partial as a string
    if (typeof response === "string") {
      rerender("full")
    } else {
      var errorsLength = response.errors.length
      for (var i = 0; i < errorsLength; i++) {
        $("<div>" + response.errors[i] + "</div>")
          .appendTo($(".search.errors"))
          .delay(4000)
          .queue(function () {
            $(this).remove()
          })
      }
      mm.drawer.spin(false)
    }
  }

  function rerender(style) {
    if (!self.$el.hasClass("showing")) _limit = 10
    style = style || "list"
    $.ajax({
      url: "/api/v1/project_searches/user",
      data: { style: style, limit: _limit },
      success: function (data) {
        mm.drawer.spin(false)
        var dataHTML = $(data)
        if (style === "list") {
          $(".list", self.$el).html(data)
        } else if (style === "full") {
          self.$el.find(".root").html(data)
          self.resize()
        }
        if ($("li", dataHTML).length < _limit)
          $("button.view-more-searches", self.$el).addClass("hide")
        build()
      },
    })
  }

  function sortStart(e, ui) {
    ui.item.addClass("dragging")
  }

  function sortStop(e, ui) {
    ui.item.removeClass("dragging")
  }

  function sortUpdate(e, ui) {
    var index = ui.item.index() + 1,
      id = parseInt(ui.item.attr("data-project-search-id"), 10),
      project_search = mm.user.project_searches.find_by("id", id)
    project_search.update({ position: index }, true)
    ui.item.removeClass("dragging")
    build()
  }

  var superShow = self.show
  self.show = function () {
    rerender("full")
    superShow()
  }

  return build()
}
