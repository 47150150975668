mm.share = {}

/**
 * Facebook SDK initialize
 * @return {[type]} [description]
 */
window.fbAsyncInit = function () {
  // init the FB JS SDK
  FB.init({
    appId: process.env.FACEBOOK_APP_ID, // App ID from the app dashboard
    //channelUrl : '//WWW.YOUR_DOMAIN.COM/channel.html', // Channel file for x-domain comms
    status: true, // Check Facebook Login status
    xfbml: true, // Look for social plugins on the page
    version: "v2.8",
  })

  // Additional initialization code such as adding Event Listeners goes here
}

// Load the SDK asynchronously
;(function (d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0]
  if (d.getElementById(id)) {
    return
  }
  js = d.createElement(s)
  js.id = id
  js.src = "//connect.facebook.net/en_US/all.js"
  fjs.parentNode.insertBefore(js, fjs)
})(document, "script", "facebook-jssdk")

/**
 * Twitter intent handling
 * @return {[type]} [description]
 */
mm.share.twitter = (function () {
  if (window.__twitterIntentHandler) return
  var intentRegex = /twitter\.com(\:\d{2,4})?\/intent\/(\w+)/,
    windowOptions = "scrollbars=yes,resizable=yes,toolbar=no,location=yes",
    width = 550,
    height = 420,
    winHeight = screen.height,
    winWidth = screen.width

  function handleIntent(e) {
    e = e || window.event
    var target = e.target || e.srcElement,
      m,
      left,
      top

    while (target && target.nodeName.toLowerCase() !== "a") {
      target = target.parentNode
    }

    if (target && target.nodeName.toLowerCase() === "a" && target.href) {
      m = target.href.match(intentRegex)
      if (m) {
        left = Math.round(winWidth / 2 - width / 2)
        top = 0

        if (winHeight > height) {
          top = Math.round(winHeight / 2 - height / 2)
        }

        window.open(
          target.href,
          "intent",
          windowOptions + ",width=" + width + ",height=" + height + ",left=" + left + ",top=" + top
        )
        e.returnValue = false
        e.preventDefault && e.preventDefault()
      }
    }
  }

  if (document.addEventListener) {
    document.addEventListener("click", handleIntent, false)
  } else if (document.attachEvent) {
    document.attachEvent("onclick", handleIntent)
  }
  window.__twitterIntentHandler = true

  return handleIntent
})()

/**
 * simple mailto: handler for sharing
 * @return {[type]} [description]
 */
mm.share.email = (function () {
  function handleEvent(e) {
    window.open(this.href, "_blank")
    e.returnValue = false
    e.preventDefault && e.preventDefault()
  }

  return handleEvent
})()
