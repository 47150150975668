/**
 * Base model object
 * @public
 * @extends {mm.EventEmitter}
 * @return {Object} Instance of mm.Model
 */
mm.Model = function (spec, defaults) {
  // copy properties to self
  var self = _.extend(
    {
      attributes: spec || {},
      id: _.uniqueId("m"),
    },
    mm.EventEmitter(),
    defaults
  )

  /**
   * Returns an attribute value
   * @param  {string} key Name of the attribute (id, name, etc)
   * @return {object}     Value of object
   */
  self.get = function (key) {
    return self.attributes[key]
  }

  /**
   * Sets an attribute value
   * @param  {string} key Name of the attribute (id, name, etc)
   * @return {mm.Model}   Self
   */
  self.set = function (key, value) {
    self.attributes[key] = value
    self.touch()
    return self
  }

  /**
   * Save the object to the server
   * @return {$.Deffered} jQuery deferred object
   */
  self.create = function (skip_render) {
    if (self.create_url) {
      var params = {}
      if (self.name) {
        params[self.name] = self.attributes
      } else {
        params = self.attributes
      }

      return $.post(self.create_url, params).done(function (data) {
        _.extend(self.attributes, data)
        if (!skip_render) {
          self.trigger("save", data)
        }
      })
    }
    throw new Error("A URL must be set on the model in order to save.")
  }

  /**
   * Save the object to the server
   * @return {$.Deffered} jQuery deferred object
   */
  self.update = function (updates, skip_render) {
    if (self.update_url) {
      var url = self.update_url + "/" + self.attributes.id.toString(),
        params = {}
      if (self.name) {
        params[self.name] = updates
      } else {
        params = updates
      }

      return $.ajax({
        url: url,
        data: params,
        type: "PUT",
      }).done(function (data) {
        _.extend(self.attributes, data)
        if (!skip_render) {
          self.trigger("update", data)
        }
      })
    }
    throw new Error("A URL must be set on the model in order to save.")
  }

  /**
   * Save the object to the server
   * @return {$.Deffered} jQuery deferred object
   */
  self.destroy = function (skip_render) {
    if (self.url) {
      var url = self.url + "/" + self.attributes.id.toString()
      return $.ajax({
        url: url,
        type: "DELETE",
      }).done(function (data) {
        if (!skip_render) {
          self.trigger("destroy", data)
        }
      })
    }
    throw new Error("A URL must be set on the model in order to destroy.")
  }

  /**
   * Trigger change event
   * @return {mm.Model} Self
   */
  self.touch = function (payload) {
    self.trigger("change", payload)
    return self
  }

  return self
}
