/**
 * Base object for all drawer forms that interact with a user that require
 * error/success handlers.
 * @constructor
 * @extends {mm.EventEmitter}
 * @public
 * @param  {$.Element} $el  The containing element
 * @param  {Object} options Form initializer object
 * @return {Object}         A new instance of mm.DrawerForm
 */
mm.DrawerForm = function ($el, options) {
  "use strict"

  if (typeof $el === "undefined") return

  options =
    options ||
    _.extend(
      {},
      {
        url: null,
        type: "GET",
        empty: true,
      }
    )

  var self = mm.EventEmitter()

  /**
   * Initializer, binds the submit event to this form's self.submit method.
   * @private
   * @return {Object} This instance of mm.DrawerForm
   */
  function init() {
    self.$form.off("submit").on("submit", self.submit)
    return self
  }

  /**
   * The containing element that wraps successes, errors, form, inputs, submits
   * @type {$.Element}
   */
  self.$el = $el

  /**
   * The form element
   * @type {$.Element}
   */
  self.$form = $("form", $el)

  /**
   * Elements to hide after success
   * @type {$.Element}
   */
  self.$hides = $(".form-hide", $el)

  /**
   * The inputs of this form
   * @type {$.Element}
   */
  self.$inputs = $("input", self.$form).add($("textarea", self.$form))

  /**
   * The submit element (button)
   * @type {$.Element}
   */
  self.$submit = $("[type=submit]", $el)

  /**
   * The error message container
   * @type {$.Element}
   */
  self.$errors = $(".errors", $el)

  /**
   * The success message container
   * @type {$.Element}
   */
  self.$success = $(".success", $el)

  /**
   * The options for this form; url, type, payload etc
   * @type {Object}
   */
  self.options = options

  /**
   * The scope applied to our parameters, or more specifically, the model we are
   * posting against. (see self.buildPayload)
   * @type {String}
   */
  self.scope = self.$form.attr("data-scope")

  /**
   * New instance of mm.Spinner
   * @type {mm.Spinner}
   */
  self.spinner = new mm.Spinner()

  /**
   * The target container for the spinner
   * @type {Element}
   */
  self.spinTarg = self.$submit.parent("div")[0]

  /**
   * Targets the Custom request form inputs
   *
   */
  if (self.$form.length > 1) {
    if (self.$form.get(0).closest('div[data-license-targ="custom"]')) {
      var $customInputs = $("input", self.$form[0]).add($("textarea", self.$form[0]))
    }
  }

  /**
   * Builds the payload object to be sent with the request from the form inputs
   * @public
   * @return {Object} The payload
   */
  self.buildPayload = function () {
    var params = {},
      payload = {}
    self.$inputs.each(function (i, input) {
      var name = input.getAttribute("name")
      params[name] = input.value
    })
    if (self.scope === "none") {
      payload = params
    } else {
      payload[self.scope] = params
    }
    return payload
  }

  /**
   * This method is always called after a response is received from the server,
   * regardless of success. Maps to jQuery always promise.
   * @public
   * @param  {Object} data The response
   * @return {Object}      This instance of mm.DrawerForm
   */
  self.complete = function (data) {
    self.spin(false)
    return self
  }

  /**
   * This method parses the returned error(s) and displays them in the errors
   * message container
   * @public
   * @param  {Array} errors The list of errors, always assumed to be Array
   * @return {Object}       This instance of mm.DrawerForm
   */
  self.handleErrors = function (response, forceEmpty) {
    var lastError
    if (options.empty || forceEmpty) {
      self.$errors.empty()
    }

    self.$inputs.removeClass("invalid")
    for (var key in response.errors) {
      var errs = response.errors[key]
      self.$inputs.filter('[name="' + key + '"]').addClass("invalid")
      _.each(errs, function (err) {
        var error
        key = key.replace("_", " ")
        key = key.charAt(0).toUpperCase() + key.slice(1)
        error = key + " " + err
        if (error !== lastError) {
          self.$errors.append($("<div/>").text(error))
        }
        lastError = error
      })
    }
    if (self.$el.selector.includes("#subscribe")) {
      self.$errors.empty()
      self.$errors.append($("<div/>").text(response.message))
    }
    self.$errors.addClass("display")
    mm.drawer.trigger("resize:" + self.$el.closest("article").attr("id"))
    return self
  }

  /**
   * This methods handles a server error and should then punt this message to
   * self.handleErrors
   * @public
   * @param  {Object} data Server response message
   * @return {Object}      This instance of mm.DrawerForm
   */
  self.handleFail = function (response) {
    self.handleErrors({ all: ["An error has occurred. Please try again later."] }, true)
    self.trigger("form:failure")
    return self
  }

  /**
   * This method handles a successful response, errors could exist. There is no
   * need to display a message generated from a server, only to replace form
   * content with the success message. (should already exist in markup)
   * @public
   * @return {Object} This instance of mm.DrawerForm
   */
  self.handleSuccess = function (response) {
    if (!response.success) return self.handleErrors(response)
    if (options.empty) {
      self.$errors.empty()
    }
    self.$errors.removeClass("display")
    self.$inputs.removeClass("invalid")
    self.$success.addClass("display")
    self.$hides.addClass("hide")
    self.$form.addClass("success")
    self.trigger("form:success")
    return self
  }

  /**
   * This method is used to reset the form to its original state and should be
   * called on drawer close.
   * @public
   * @return {Object} This instance of mm.DrawerForm
   */
  self.reset = function () {
    if (options.empty) {
      self.$errors.empty()
    }
    self.$errors.removeClass("display")
    if (mm.isMobile) {
      self.$inputs.removeClass("invalid")
    } else {
      self.$inputs.removeClass("invalid").first().focus()
    }
    self.$success.removeClass("display")
    self.$hides.removeClass("hide")
    self.$form.removeClass("success")
    if (self.$form[0]) self.$form[0].reset()
    if (self.$el.closest("article").is("#register"))
      self.$form.find("button").prop("disabled", true)
    return self
  }

  /**
   * Method to show that the form is being processed.
   * @public
   * @param  {Boolean} spin Show/Hide spinner
   */
  self.spin = function (spin) {
    if (spin) {
      self.$el.addClass("processing")
      self.$inputs.attr("disabled", true)
      self.$submit.attr("disabled", true)
      self.spinner.spin(self.spinTarg)
      mm.drawer.spin(true)
    } else {
      self.$el.removeClass("processing")
      self.$inputs.removeAttr("disabled")
      self.$submit.removeAttr("disabled")
      self.spinner.stop()
      mm.drawer.spin(false)
    }
  }

  /**
   * This method is the 'submit' event handler for this form
   * @public
   * @return {Object} This instance of mm.DrawerForm
   */
  self.submit = function (e) {
    e.preventDefault()

    if ($(this).attr("id") === "custom-submission") {
      self.$inputs = $customInputs
    }

    var payload = self.buildPayload()

    self.spin(true)
    $.ajax({
      url: self.options.url,
      type: self.options.type,
      data: payload,
    })
      .done(self.handleSuccess)
      .fail(self.handleFail)
      .always(self.complete)
    return self
  }

  return init()
}
