/**
 * The messageCenter is used as a central point of communication to handle the
 * displaying of messages within a modal - this object is necessary to ensure
 * messages do not become 'stacked' on top of eachother.
 * @constructor (singleton)
 * @extends {mm.EventEmitter}
 * @public
 * @return {Object} mm.messageCenter
 */
mm.messageCenter = (function () {
  var self = mm.EventEmitter()

  /**
   * Initializer, creates props
   * @public
   * @return {Object} mm.messageCenter
   */
  self.init = function () {
    self.$el = $("#message-center").attr("tabindex", -1)
    // TODO: replace oak logic here
    // self.__el = oak.strap(self.$el[0])
    self.$close = $(".close", self.$el)
    self.$cover = $(".cover", self.$el)
    self.$target = $(".target", self.$el)

    self.$close.on("click", function (e) {
      e.preventDefault()
      self.close()
    })

    self.$cover.on("click", function (e) {
      e.preventDefault()
      self.close()
    })

    self.$el.on("keydown", function (e) {
      if (e.keyCode === 27) {
        e.preventDefault()
        self.close()
      }
    })

    return self
  }

  /**
   * Checks the queue for an available message and calls to display it
   * @private
   */
  function checkQueue() {
    var message = self.queue.shift()
    if (message) {
      display(message)
    }
  }

  function display(message) {
    var message_id = message.content.attr("class")

    if (message_id === "playerWrapper") {
      self.$el.addClass("videoPlayerContainer")
    }

    self.$target.html(message.content)
    self.$el.addClass("showing").removeClass("hiding").attr("data-id", message_id).focus()

    self.$target.find("#closeModal").on("click", function (e) {
      e.preventDefault()
      self.close()
    })

    if (message_id === "playerWrapper") {
      self.$target.find(".iframeWrapper iframe").on("load", function () {
        self.$target.find(".playerWrapper .loading").addClass("hidden")
      })
    }

    if (typeof message.callback === "function") {
      message.callback()
    }
    checkQueue()
  }

  /**
   * Queue of mm.Message instances to display
   * @type {Array}
   */
  self.queue = []

  self.close = function () {
    self.$el.addClass("hiding").removeClass("showing")
    self.$el[0].addEventListener(
      "transitionend",
      function onClose() {
        self.$el.removeClass("hiding")
        self.$el[0].className = ""
        self.$close[0].className = "close"
        self.$target.html("")
        self.$el[0].removeEventListener("transitionend", onClose)
      },
      false
    )
    if ($("div.past-licenses.disabled").length > 0) {
      $("div.past-licenses").removeClass("disabled")
    }
  }

  /**
   * Accepts a message and calls to check the queue
   * @public
   * @param  {mm.Message} message An instance of mm.Message
   */
  self.read = function (message) {
    // if there is no message, exit.
    if (typeof message === "undefined") return
    self.queue.push(message)
    checkQueue()
  }

  return self
})()

mm.facade.on("app:ready", mm.messageCenter.init)
