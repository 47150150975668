/**
 * Scripts specific to the drawer register page
 * @public
 * @return {Object} Instance of mm.drawerRegisterPage (Singleton)
 */

mm.DrawerRegisterPage = function () {
  var self = mm.DrawerPage($(".mm-drawer-wrap #register")),
    form = mm.DrawerForm($(".mm-drawer-form", self.$el), {
      url: "/register",
      type: "POST",
      empty: true,
    }),
    newsletterCheckbox = $("#newsletter", self.$el),
    termsCheckbox = $("#agree_to_terms", self.$el),
    registerButton = $("#create-account button", self.$el)

  // Set newsletter opt-in to true if checked, false if not
  newsletterCheckbox.on("change", function () {
    $(this).val(this.checked)
    $(this).attr("aria-checked", this.checked)
  })

  termsCheckbox.on("change", function () {
    registerButton.prop("disabled", !this.checked)
  })

  var superHide = self.hide
  self.hide = function () {
    superHide()
    form.reset()
  }

  var superShow = self.show
  self.show = function () {
    superShow()
    form.reset()
  }

  return self
}
