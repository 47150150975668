mm.ProfileForm = function (context, catchSubmit) {
  "use strict"

  if (typeof context === "undefined") return

  if (typeof catchSubmit === "undefined") {
    catchSubmit = true
  }

  var form = context.find("form")

  var self = {},
    $button = $("button[type=submit]", form),
    $country = $("select#user_address_attributes_country", form),
    $errors = $("#edit-errors", context),
    $job = $("input#user_job", form),
    $other = $("input#job_other", form),
    $radios = $("ul li a", form),
    $select = $("select", form),
    $spinTarg = $button.parent("div"),
    $success = $("#edit-success", context),
    _countryVal = $country.find(":selected").text(),
    _opts = [],
    _selectable,
    _spinner = new mm.Spinner()

  function init() {
    $("option", $select).each(function (i, option) {
      if ($(option).attr("disabled")) return
      var opt = { text: option.value, data: option.value }
      _opts.push(opt)
    })

    _selectable = mm.Selectable($select, _opts)
    var superSelected = _selectable.selected
    _selectable.selected = function (opt) {
      superSelected(opt)
      form.find("select#user_address_attributes_country").val(opt.value)
    }

    $radios.on("click", function (e) {
      e.preventDefault()
      var radio = $(this),
        val = radio.attr("data-value")
      $radios.removeClass("active")
      radio.addClass("active")
      if (val === "Other") {
        $job.attr("disabled", true)
        $other.attr("disabled", false)
        $other[0].focus()
      } else {
        $other.attr("disabled", true).val("")
        $job.attr("disabled", false).val(val)
      }
    })

    if ($job.val()) {
      var radio = $radios.filter('[data-value="' + $job.val() + '"]')
      if (radio.length) {
        radio.addClass("active")
      } else {
        $radios.filter('[data-value="Other"]').addClass("active")
        $other.attr("disabled", false).val($job.val())
        $job.attr("disabled", true)
      }
    }

    if (_countryVal) {
      var opt = { html: _countryVal, value: _countryVal }
      _selectable.selected(opt)
    }

    if (catchSubmit) {
      form.on("submit", onSubmit)
    }
    return self
  }

  function disable() {
    form.addClass("processing")
    $button.prop("disabled", true)
    _spinner.spin($spinTarg[0])
  }

  function enable() {
    form.removeClass("processing")
    $button.prop("disabled", false)
    _spinner.stop()
  }

  function onSubmit(e) {
    e.preventDefault()
    var _data = form.serialize()
    disable()
    $errors.add($success).html("")
    $("input", form).removeClass("invalid")
    $.post("/account/update", _data)
      .done(function (data) {
        if (data.success && data.message) {
          $success.append($("<p/>").text(data.message))
        } else {
          handleErrors(data)
        }
      })
      .fail(handleErrors)
      .always(enable)
    return false
  }

  function handleErrors(response) {
    var lastError
    for (var key in response.errors) {
      var errs = response.errors[key]
      $('input[name="user[' + key + ']"]', form).addClass("invalid")
      _.each(errs, function (err) {
        var error
        key = key.replace("_", " ")
        key = key.charAt(0).toUpperCase() + key.slice(1)
        error = key + " " + err
        if (error !== lastError) {
          $errors.append($("<p/>").text(error))
        }
        lastError = error
      })
    }
  }

  return init()
}
