mm.SongVersion = function ($el) {
  "use strict"

  if ($el === undefined) return

  var self = mm.EventEmitter(),
    $actions,
    _hovers = [],
    _paused = false,
    timeoutID,
    $mixtapePage = $el.closest("section#mm-mixtape"),
    $unhiddenBtn = $(".unhide", $el),
    $hiddenBtn = $(".hide", $el),
    $pastLicensesButton = $("div.past-licenses", $el),
    $rankingInfoButton = $("div.ranking-info", $el),
    $stemsIcon = $("div.stems", $el),
    $actionsMenu = $("li.actions-menu", $el),
    $expandActionsMenu = $(".expand-actions-menu .vertical-ellipsis", $el),
    $favoriteIcon = $(".favorite-icon", $el),
    $favoriteButton = $("li.favorite", $el),
    $downloadButton = $("li.download", $el),
    $mixtapeButton = $("li.mixtape", $el),
    $projectSearchButton = $("li.project-search", $el),
    $downloadPartialExTooltip = $(
      "li.actions-menu li.tooltip[data-connect-action='download']",
      $el
    ),
    $projectSearchPartialExTooltip = $(
      "li.actions-menu li.tooltip[data-connect-action='project-search']",
      $el
    ),
    $tooltips = $("li.tooltip", $el),
    $song,
    $catalogColor,
    $removeArchived = $(".remove-archived-message", $el),
    $internal = ["admin", "business_affairs", "staff", "marketing", "a_and_r"]

  function init() {
    $catalogColor = $el.data("catalog-color")
    var hovers = $(".mm-hover-connect", $el)
    $song = $el.closest(".song-item")
    $actions = $(".action", $el).not(".next").not(".prev").not(".download")

    // redirect to login for guest users
    if (!mm.user.attributes.hasOwnProperty("id")) {
      hydrateGuest()
      hydrateNonInternal("guest")
    } else {
      var loginLink = $("li.download a[itemprop='contentUrl']", $el)
      loginLink.remove()

      // hydrate user data
      var interal_or_ip =
        mm.user.attributes.role === "international_partner" ||
        $internal.includes(mm.user.attributes.role)
          ? true
          : false

      if (interal_or_ip) {
        $pastLicensesButton.removeClass("hidden")
        $rankingInfoButton.removeClass("hidden")
        $stemsIcon.removeClass("hidden")

        if ($catalogColor) {
          applyCatalogColor()
        }
      }

      hydrateNonInternal(mm.user.attributes.role)
      hydrateHidden(self.data.song_id)
      hydrateFavorite(self.data.id)
    }

    $actions.on("click", function (e) {
      var action = $(this).attr("data-action")
      if (action in self) self[action](this)
    })

    if ($tooltips.length) {
      $tooltips.each(function (ind, tooltip) {
        var tooltip = $(tooltip)
        var action = tooltip.data("connect-action")
        var ttButton = tooltip.closest("ol").find("li.action." + action)

        ttButton.on("mouseover.tooltip", function () {
          clearTimeout(timeoutID)
          $tooltips.removeClass("show")
          tooltip.addClass("show")
        })
        ttButton.on("mouseleave", function () {
          timeoutID = setTimeout(hideTooltip, 100)
        })
      })
    }
    // adjust position of Partial Exclusivity tooltip depending on where Download action is located in action menu
    if ($downloadPartialExTooltip.length > 0) {
      var $download = $("li.download", $el),
        top = $download.offset().top - $download.parent().offset().top - 10

      $downloadPartialExTooltip.css("top", top + "px")
    }
    // adjust position of Partial Exclusivity tooltip depending on where Project Search action is located in action menu
    if ($projectSearchPartialExTooltip.parent().length > 0) {
      var $projectSearch = $("li.project-search", $el),
        top = $projectSearch.offset().top - $projectSearch.parent().offset().top - 10

      $projectSearchPartialExTooltip.css("top", top + "px")
    }
    $expandActionsMenu.off("click")
    $expandActionsMenu.on("click", function () {
      $(this).hasClass("expanded") ? hideActionsMenu() : showActionsMenu()
    })
    if (hovers.length) {
      hovers.each(function (i, hover) {
        _hovers.push(mm.HoverConnect($(hover), $el))
      })
    }
    showModal()
    closeModal()
    return self
  }

  function hideActionsMenu() {
    $expandActionsMenu.removeClass("expanded")
    $actionsMenu.removeClass("expanded")
  }

  function showActionsMenu() {
    $("li.actions-menu", "li.song-version-item").removeClass("expanded")
    $(".expand-actions-menu .vertical-ellipsis", "li.song-version-item").removeClass("expanded")
    $expandActionsMenu.addClass("expanded")
    $actionsMenu.addClass("expanded")
  }

  function showModal() {
    $("li.action.add-to-blanket-deal").on("click", function () {
      $(this).next("#mm-select-blanket-order-modal").removeClass("hidden")
    })
  }

  function closeModal() {
    $(document).on("click", ".blanket-order-cancel-button", function () {
      if (!$(this).closest("#mm-select-blanket-order-modal").hasClass("hidden")) {
        $(this).closest("#mm-select-blanket-order-modal").addClass("hidden")
      }
    })
  }

  function hideTooltip() {
    $tooltips.removeClass("show")
  }

  function fetchDataForMessagePopUp(route) {
    $.get(route + self.data.id).done(function (data) {
      var msg = mm.Message(data)
      if (msg) {
        msg.send()
      }
    })
  }

  function postFavorite(request, songVersionID) {
    if (_.isUndefined(songVersionID)) return false
    var url = "/favorites/" + request + "/song_version/" + songVersionID
    var songDataAttr = "[data-song-version-id=" + songVersionID + "]"

    $.post(url).done(function () {
      if (request === "remove") {
        toggleSongRowFavorite(request, songDataAttr)
        togglePlayerFavorite(request, songDataAttr)
        removeFavorite(songVersionID)
      } else {
        toggleSongRowFavorite(request, songDataAttr)
        togglePlayerFavorite(request, songDataAttr)
        addFavorite()
      }
      mm.user.fetchUserSongs()
    })
  }

  function toggleSongRowFavorite(request, songDataAttr) {
    var $songRowfavoriteText = $(".favorite-text", songDataAttr)
    var $songShowPageFavoriteText = $(
      '.add-favorite .label, .connect-label:contains("Favorite")',
      songDataAttr
    )
    var $songShowPageFavoriteConnectText = $(
      ".mm-connect-song-version-actions .connected",
      songDataAttr
    )
    var $songRowFavorited = $(".default .actions-menu, .favorited", songDataAttr)
    var $songRowUnfavorited = $(".default .actions-menu, .unfavorited", songDataAttr)
    var text = request === "remove" ? "Add To Favorites" : "Remove Favorite"

    $songRowfavoriteText.text(text)
    $songShowPageFavoriteText.text(text)
    $songShowPageFavoriteConnectText.text(text)
    $songRowFavorited.toggleClass("selected")
    $songRowUnfavorited.toggleClass("hiding")
  }

  function togglePlayerFavorite(request, songDataAttr) {
    var $playerFavoriteIcon = $(".add-favorite .favorite-icon", songDataAttr)
    var $playerFavoriteText = $(
      '.add-favorite .label, .connect-label:contains("Favorite")',
      songDataAttr
    )
    var $playerFavoriteConnectText = $(".mm-connect-right .connected", songDataAttr)

    var text = request === "remove" ? "Add To Favorites" : "Remove Favorite"
    $playerFavoriteText.text(text)
    $playerFavoriteConnectText.text(text)
    $playerFavoriteIcon.toggleClass("selected")
  }

  // addFavorites only increcments the number
  // on the heading of the Favorites page
  function addFavorite() {
    if ($("#mm-favorites").length > 0) {
      var favInfo = $("li", "div.info"),
        favNum = Number(favInfo.text().replace(/\D/g, "")) + 1
      favNum === 1
        ? favInfo.text("You have " + favNum + " Favorited Song")
        : favInfo.text("You have " + favNum + " Favorited Songs")
    } else {
      return false
    }
  }

  function removeFavorite(songVersionID) {
    var $songVersion = $("li[data-song-version-id=" + songVersionID + "]")

    if ($("#mm-favorites").length > 0) {
      var favInfo = $("li", "div.info"),
        favNum = Number(favInfo.text().replace(/\D/g, "")) - 1
      favNum === 1
        ? favInfo.text("You have " + favNum + " Favorited Song")
        : favInfo.text("You have " + favNum + " Favorited Songs")

      $songVersion.slideUp({ duration: 500, easing: "easeOutExpo" })
    } else {
      return false
    }
  }

  function hideOrUnhide(request) {
    var hideBtn = $unhiddenBtn.length > 0 ? $unhiddenBtn : $hiddenBtn,
      hideBtnText = $("li.show-hide .option-text", $el),
      hideIcon = $("li.show-hide span.hide-icon", $el),
      unhideIcon = $("li.show-hide span.unhide-icon", $el)

    if (request === "hide") {
      hideBtn.addClass("hide").removeClass("unhide")
      hideBtnText.text("Show")
      $el.addClass("hidden")
      unhideIcon.addClass("hidden")
      hideIcon.removeClass("hidden")
    } else {
      hideBtn.addClass("unhide").removeClass("hide")
      hideBtnText.text("Hide")
      $el.removeClass("hidden")
      hideIcon.addClass("hidden")
      unhideIcon.removeClass("hidden")
    }
  }

  function hydrateHidden(songID) {
    var hideBtn = $("li.show-hide", $el)
    if (
      mm.user.hiddenSongs.hasOwnProperty(songID) &&
      mm.user.hiddenSongs[songID]["hidden"] === true
    ) {
      hideOrUnhide("hide")
    }
  }

  function hydrateFavorite(id) {
    if (mm.user.favoriteSongVersions.hasOwnProperty(id)) {
      var songDataAttr = "[data-song-version-id=" + id + "]"
      toggleSongRowFavorite("add", songDataAttr)
      togglePlayerFavorite("add", songDataAttr)
    }
  }

  function hydrateNonInternal(role) {
    // if user is not internal, remove project search options and past licenses button
    if ($internal.indexOf(role) < 0) {
      var tooltipWrapper = $projectSearchPartialExTooltip.parent()
      tooltipWrapper.empty()
      tooltipWrapper.remove()
      $projectSearchButton.remove()
      if (self.data.exclusive) {
        var downloadLink = $("li.download a#downloadLink", $el)
        downloadLink.removeAttr("href")
        $downloadButton.addClass("disabled")
      }
    }
  }

  function hydrateGuest() {
    $unhiddenBtn.attr("data-action", "login")
    $unhiddenBtn.addClass("disabled")

    $favoriteButton.attr("data-action", "login")
    $favoriteButton.addClass("disabled")

    $mixtapeButton.attr("data-action", "login")
    $mixtapeButton.addClass("disabled")

    $downloadButton.attr("data-action", "login")
    $downloadButton.addClass("disabled")

    var downloadLink = $("li.download a#downloadLink", $el)
    downloadLink.remove()
  }

  function applyCatalogColor() {
    $el.css({ background: $catalogColor })
  }

  self.data = JSON.parse($el.attr("data-song-version") || "{}")

  self.customize = function () {
    mm.drawer.trigger("customize", self.data.id)
  }

  self.destroy = function () {
    $actions.off("click")
    if (_hovers.length) {
      _hovers.forEach(function (hover) {
        hover.destroy()
      })
    }
  }

  self.license = function (el) {
    sessionStorage.setItem("customRequestData", JSON.stringify(self.data))

    if ($(el).hasClass("custom-only")) {
      mm.router.navigate("/licensing/custom-request", { trigger: true })
    } else {
      mm.drawer.trigger("cart:add", self.data)
    }
  }

  self.pastLicenses = function () {
    $pastLicensesButton.addClass("disabled")
    fetchDataForMessagePopUp("/song_versions/past_licenses/")
  }

  self.lyrics = function () {
    fetchDataForMessagePopUp("/song_versions/lyrics/")
  }

  self.favorite = function (e) {
    var request = $favoriteIcon.hasClass("selected") ? "remove" : "add"
    postFavorite(request, self.data.id)
  }

  self.mixtape = function () {
    mm.drawer.trigger("mixtape:add", self.data.id)
  }

  self.projectSearch = function () {
    mm.drawer.trigger("projectSearch:add", _.pick(self.data, "id", "song_id"))
  }

  self.hide = function () {
    var hideBtn = $unhiddenBtn.length > 0 ? $unhiddenBtn : $hiddenBtn,
      request = hideBtn.hasClass("hide") ? "unhide" : "hide"

    hideOrUnhide(request)

    $.post("/song/" + self.data.song_id + "/" + request).done(function (data) {
      if (data.status !== "success") {
        if (request === "hide") {
          hideBtn.addClass("unhide").removeClass("hide")
          hideBtnText.text("Hide")
          $el.removeClass("hidden")
        } else {
          hideBtn.addClass("hide").removeClass("unhide")
          hideBtnText.text("Show")
          $el.addClass("hidden")
        }
      }
      mm.user.fetchUserSongs()
    })
  }

  self.pause = function () {
    _paused = true
    mm.playerProxy.trigger("pause")
  }

  self.play = function () {
    if (_paused) {
      mm.playerProxy.trigger("play")
      _paused = false
    } else {
      // if song version is on a mixtape page, load mixtape into player
      if ($mixtapePage.length > 0) {
        var mixtapeData = JSON.parse($mixtapePage.find("[data-mixtape]").attr("data-mixtape"))

        mm.playerProxy.trigger("play", {
          pointer: self.data,
          songVersions: mixtapeData.song_versions,
          type: "mixtape",
        })

        // else just play the song
      } else {
        mm.playerProxy.trigger("play", self.data)
      }
    }
    if ($song.length === 0) {
      $(".playing", $el.parent()).removeClass("playing")
    } else {
      $(".playing", $song.parent()).removeClass("playing")
    }
    $el.addClass("playing")
  }

  self.share = function () {
    $("#share").addClass("share-song-version")
    var data = {
      type: "song version",
      id: self.data.id,
      segment: "browse",
      api: "song_versions",
      songVersion: {
        id: self.data.id,
        name: self.data.display_name,
        artist: self.data.artist.name,
      },
    }
    mm.drawerProxy.trigger("share:songVersion", data)
  }

  self.login = function () {
    mm.drawerLoginPage.notify(
      "You must be logged in to perform this action. Sign in or register below."
    )
    mm.drawer.trigger("login")
  }

  return init()
}
