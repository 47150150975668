mm.drawer = (function () {
  ;("use strict")

  var self = mm.EventEmitter()

  self._callbacks = []

  self.$container = $("#container")

  /**
   * Main DOM context
   * @public
   * @type {$.Element}
   */
  self.$el = $("#mm-drawer")

  /**
   * The global page header
   * @public
   * @type {$.Element}
   */
  self.$header = $("header[role=main]")

  /**
   * The mobile anchor that opens the drawer; mobile only
   * @public
   * @type {$.Element}
   */
  self.$menuAnchor = $("nav[role=main] .menu")
  self.$playerAnchor = $("nav[role=main].mobile .playing-menu")

  /**
   * selector for the desktop nav, this is used to build out inline css
   * @type {String}
   */
  self.desktopNav = "#mm-drawer-nav.desktop"
  self.$desktopNav = $(self.desktopNav)

  /**
   * The nav container for internal drawer pages; desktop only
   * @public
   * @type {$.Element}
   */
  self.$nav = $("#mm-drawer-nav.desktop nav")

  /**
   * The mobile specific nav for internal drawer pages; mobile only
   * @type {[type]}
   */
  self.$navMobile = $("nav[role=drawer]", self.$el)

  /**
   * Drawer navigation links specific to an unregistered user; mobile only
   * @public
   * @type {$.Element}
   */
  self.$navMobileGuestLinks = $("li.guest", self.$navMobile)

  /**
   * The individual links to which user events will be bound; mobile only
   * @public
   * @type {$.Element}
   */
  self.$navMobileLinks = $("a", self.$navMobile)

  /**
   * Drawer navigation links specific to a registered user; mobile only
   * @public
   * @type {$.Element}
   */
  self.$navMobileUserLinks = $("li.user", self.$navMobile)

  /**
   * Drawer navigation links specific to an unregistered user
   * @public
   * @type {$.Element}
   */
  self.$navGuestLinks = $("li.guest", self.$nav)

  /**
   * The individual links to which user events will be bound.
   * @public
   * @type {$.Element}
   */
  self.$navLinks = $("a", self.$nav)

  /**
   * Drawer navigation links specific to a registered user
   * @public
   * @type {$.Element}
   */
  self.$navUserLinks = $("li.user", self.$nav)

  /**
   * Drawer navigation links specific to an internal user
   * @public
   * @type {$.Element}
   */
  self.$navInternalUserLinks = $("li.internal-user", self.$nav)

  /**
   * Wraps everything within the drawer
   * @public
   * @type {$.Element}
   */
  self.$shelf = $(".mm-drawer-shelf", self.$el)

  /**
   * Nav bar at the top of the drawer shelf, the height of this
   * is used to subject from the height of the body to arrive at
   * the proper height for the visible drawer <article>
   * @type {[type]}
   */
  self.$shelfDrawerNavInternal = $(".mm-drawer-nav-internal", self.$shelf).first()

  /**
   * The draw wrapper that we size height to create proper vertical
   * scroll
   * @type {[type]}
   */
  self.$shelfDrawerWrap = $(".mm-drawer-wrap", self.$shelf).first()

  /**
   * Close button located on shelf, closes the drawer
   * @public
   * @type {$.Element}
   */
  self.$shelfClose = $(".close", self.$shelf)

  /**
   * Anchor linking to the mobile nav; mobile only
   * @public
   * @type {[type]}
   */
  self.$shelfNavAnchor = $("a.nav", self.$shelf)

  /**
   * Mobile internal drawer navigation links; mobile only
   * @public
   * @type {$.Element}
   */
  self.$shelfNavAnchorLinks = $("nav[role=main] > ol > li > a", self.$el)

  self.spinner = new mm.Spinner()

  self.$target = $("#drawer-target")

  /**
   * Flag, reflects if the drawer should delay hiding a drawerPage until after
   * the drawer has closed.
   * @type {Boolean}
   */
  self.delay = false

  /**
   * Flag, reflects if the desktop nav bar has been pushed open or is pulled closed
   * @public
   * @type {Boolean}
   */
  self.pushed = false

  /**
   * Flag, reflects if the desktop nav bar is curently pushing
   * @public
   * @type {Boolean}
   */
  self.pushing = false

  /**
   * Storage for the current user's status to set the appropriate state
   * @public
   * @type {String}
   */
  self.userOrGuest = mm.user.isLoggedIn() ? "user" : "guest"

  self._buildCSS = function () {
    self.$container = self.$container || $(".container-selector")
    self.$desktopNav = self.$desktopNav || $(".desktop-nav-selector")
    // Check for vertical or horizontal scroll bar presence
    if (
      self.$container.get(0).scrollHeight > self.$container.height() ||
      self.$container.get(0).scrollWidth > self.$container.width()
    ) {
      self.$desktopNav.addClass("scroll-bar")
    } else {
      self.$desktopNav.removeClass("scroll-bar")
    }
  }

  self.init = function () {
    self._callbacks = []
    self.$container = $("#container")
    self.$el = $("#mm-drawer")
    self.$header = $("header[role=main]")
    self.$menuAnchor = $("nav[role=main] .menu")
    self.$playerAnchor = $("nav[role=main].mobile .playing-menu")
    self.desktopNav = "#mm-drawer-nav.desktop"
    self.$desktopNav = $(self.desktopNav)
    self.$nav = $("#mm-drawer-nav.desktop nav")
    self.$navMobile = $("nav[role=drawer]", self.$el)
    self.$navMobileGuestLinks = $("li.guest", self.$navMobile)
    self.$navMobileLinks = $("a", self.$navMobile)
    self.$navMobileUserLinks = $("li.user", self.$navMobile)
    self.$navGuestLinks = $("li.guest", self.$nav)
    self.$navLinks = $("a", self.$nav)
    self.$navUserLinks = $("li.user", self.$nav)
    self.$navInternalUserLinks = $("li.internal-user", self.$nav)
    self.$shelf = $(".mm-drawer-shelf", self.$el)
    self.$shelfDrawerNavInternal = $(".mm-drawer-nav-internal", self.$shelf).first()
    self.$shelfDrawerWrap = $(".mm-drawer-wrap", self.$shelf).first()
    self.$shelfClose = $(".close", self.$shelf)
    self.$shelfNavAnchor = $("a.nav", self.$shelf)
    self.$shelfNavAnchorLinks = $("nav[role=main] > ol > li > a", self.$el)
    self.$target = $("#drawer-target")
    self.delay = false
    self.pushed = false
    self.spinner = new mm.Spinner()
    self.userOrGuest = mm.user.isLoggedIn() ? "user" : "guest"

    var pageObjects = [
      "CartPage",
      "CartbenchPage",
      "CustomizePage",
      "LoginPage",
      "MixbenchPage",
      "MixtapesPage",
      "NavPage",
      "ProjectSearchbenchPage",
      "ProjectSearchesPage",
      "RegisterPage",
      "SharePage",
      "NewsletterPage",
      "ActivationPage",
    ]
    function initializePageObject(pageObjectName) {
      var drawerKey = `drawer${pageObjectName}`
      var globalKey = `Drawer${pageObjectName}`

      if (mm[globalKey]) {
        mm[drawerKey] = mm[globalKey]()
      } else {
        var onReadyKey = `on${globalKey}Ready`
        mm[onReadyKey] = function () {
          mm[drawerKey] = mm[globalKey]()
        }
      }
    }
    pageObjects.forEach(function (pageObjectName) {
      initializePageObject(pageObjectName)
    })

    self._buildCSS()
    self.trigger("calc")
    self.bindEvents()
    self.close()
  }

  /**
   * calculates the height of the drawer <article> for scrolling purposes
   * originally using css calc() but in conjuction with a transition it
   * crashes safari.
   * http://stackoverflow.com/questions/14055461/ios-safari-css-calc-css-transition-instant-crash
   */
  self.on("calc", function () {
    //var h = window.innerHeight - self.$shelfDrawerNavInternal.height();
    //self.$shelfDrawerWrap.height(h);
  })

  /**
   * Bind any user interaction events here
   * @public
   */
  self.bindEvents = function () {
    self.$navLinks
      .add(self.$navMobileLinks)
      .off("click")
      .on("click", function (e) {
        e.preventDefault()
        var targ = $(this).attr("href").replace(/^#/, "")
        if (targ.includes("/users/")) {
          return mm.router.navigate(`/users/${mm.user.attributes.id}`, { trigger: true })
        }
        if (targ === "favorites") {
          return mm.router.navigate("/favorites", { trigger: true })
        }
        // Hacky solution: once page is routed to /manage force a reload since
        // Backbone won't allow routing to active admin directly
        if (targ === "manage") {
          window.location.href = "/manage"
          return true
        }
        var state = targ === "project-searches" ? "projectSearches" : targ

        self._current(targ)
        self.setState(state)
        self.delay = false
      })

    self.$shelfClose.on("click", function (e) {
      e.preventDefault()
      self.delay = true
      self.setState(self.userOrGuest)
    })

    self.$menuAnchor.off("click").on("click", function (e) {
      e.preventDefault()
      if (self.$desktopNav.hasClass("pushed")) {
        self.close()
      } else {
        self.setState("nav")
      }
    })

    self.$playerAnchor.on("click", function (e) {
      e.preventDefault()
      self.setState("nav")
    })

    self.$shelfNavAnchor.on("click", function (e) {
      e.preventDefault()
      self.setState("nav")
    })

    self.$shelfNavAnchorLinks.on("click", function (e) {
      if ($(this).attr("data-external")) {
        return true
      }
      e.preventDefault()
      self.close()
    })
  }

  /**
   * Exposes close functionality to drawerPages, checks guest status and sets
   * state accordingly.
   * @public
   * @param  {Function} callback Usually a drawerPage.hide method
   */
  self.close = function (callback) {
    self.userOrGuest = mm.user.isLoggedIn() ? "user" : "guest"
    self.delay = true
    self.setState(self.userOrGuest)
    if (typeof callback === "function") {
      self._checkDelay(callback)
    }
  }

  /**
   * Checks to see if the drawer should delay hiding a drawerPage until after
   * the drawer has closed; to be used internally.
   * @public
   * @param  {Function} callback Function to call, either delayed or not
   * @return {Boolean}
   */
  self._checkDelay = function (callback) {
    if (typeof callback !== "function") return false
    if (self.delay && typeof callback === "function") {
      self._callbacks.push(callback)
      // setTimeout(callback, 500);
      return true
    }
    callback()
    return false
  }

  /**
   * Applies a current state to the desktop drawer navigation; desktop only
   * @public
   * @param  {String} targ The link to be made current
   */
  self._current = function (targ) {
    self.$navLinks
      .removeClass("over")
      .filter('[href="#' + targ + '"]')
      .addClass("over")
  }

  /**
   * Removes classes to 'pull' the drawer closed; to be used internally
   * @public
   * @return {Boolean}
   */
  self._pull = function () {
    if (self.pushed) {
      self.$desktopNav.removeClass("pushed")
      self.$el.removeClass("viewing")
      self.$shelf.removeClass("onscreen")
      self.$navLinks.removeClass("over")
      self.pushed = false
      $("#container").off("click")
      self.$container[0].addEventListener("transitionend", function tranEnd() {
        var call
        while ((call = self._callbacks.shift())) call()
        self.$container[0].removeEventListener("transitionend", tranEnd)
      })
      return true
    }
    return false
  }

  /**
   * Adds classes to 'push' the drawer open; to be used internally.
   * @public
   * @return {Boolean}
   */
  self._push = function () {
    self.pushing = true

    var pushed = false

    if (mm.isMobile) {
      self.trigger("calc")
    }

    if (!self.pushed) {
      self.$desktopNav.addClass("pushed")
      self.$el.addClass("viewing")
      self.$shelf.addClass("onscreen")
      self.pushed = true

      // on page click, close drawer
      _.defer(function () {
        $("#container").on("click", function () {
          if (!self.pushing) {
            mm.drawer.trigger("close")
          }
        })
      })

      pushed = true
    }

    _.defer(function () {
      self.pushing = false
      self.delay = false
    })

    return pushed
  }

  self._render = function () {
    $.get("/render_drawer").done(function (data) {
      self.$target.html(data)
      self.init()
    })
  }

  self.spin = function (spin) {
    if (spin) {
      self.spinner.spin(self.$shelfClose[0])
      self.$shelfClose.addClass("spinning")
    } else {
      self.spinner.stop()
      self.$shelfClose.removeClass("spinning")
    }
  }

  self._onOrderLoginPage = function () {
    return /click_order\/[0-9]*\/login/.test(Backbone.history.fragment)
  }

  self._attachOrderToLoginForm = function () {
    const orderId = window.location.pathname.match(/\d+/)[0]
    $("#orderId").val(orderId)
  }

  /**
   * State Machine map, do not overwrite, only extend.
   * @public
   * @type {Object}
   */
  self.states = {
    onGuest: function () {
      self.$navGuestLinks.add(self.$navMobileGuestLinks).removeClass("hidden")
      self.$navUserLinks.add(self.$navMobileUserLinks).addClass("hidden")
      self.$navInternalUserLinks.addClass("hidden")
      self._pull()
    },
    onUser: function () {
      self.$navGuestLinks.add(self.$navMobileGuestLinks).addClass("hidden")
      self.$navUserLinks.add(self.$navMobileUserLinks).removeClass("hidden")
      if (mm.user.attributes.internal_user) {
        self.$navInternalUserLinks.removeClass("hidden")
      }
      self._pull()
    },

    onCart: function () {
      mm.drawerCartPage.show()
      self._push()
    },
    offCart: function () {
      self._checkDelay(mm.drawerCartPage.hide)
    },

    onCartbench: function (payload, remove) {
      mm.drawerCartbenchPage.show(payload, remove)
      self._push()
    },
    offCartbench: function () {
      self._checkDelay(mm.drawerCartbenchPage.hide)
    },

    onCustomize: function (payload) {
      mm.drawerCustomizePage.show(payload)
      self._push()
    },
    offCustomize: function () {
      self._checkDelay(mm.drawerCustomizePage.hide)
    },

    onLogin: function () {
      var $link = $(".dashboard-link")
      var $searchLink = $(".project-searches")
      mm.drawerLoginPage.show()
      self._push()
      if (self._onOrderLoginPage()) self._attachOrderToLoginForm()

      mm.user.once("logged_in", function () {
        if (self._onOrderLoginPage()) {
          self.close(function () {})
        } else if (mm.isMobile) {
          self.close()
          window.location.reload()
        } else {
          self.close(function () {
            mm.facade.trigger("refresh")
          })
        }

        const userType = mm.user.attributes.role
        const isInternalUser = mm.user.attributes.internal_user
        if (!$link.hasClass("available")) {
          if (isInternalUser || userType === "international_partner") $link.text("Portal")
          if (userType === "vendor") $link.text("Artist Portal")
          $link.addClass("available")
          $("nav[role=main].desktop ol li").addClass("condensed")
        }
        if (!isInternalUser) {
          $searchLink.addClass("hidden")
        }
      })
    },
    offLogin: function () {
      self._checkDelay(mm.drawerLoginPage.hide)
    },

    onLogout: function () {
      var $link = $(".dashboard-link")
      mm.user.logout(self.close)
      mm.user.once("logged_out", function () {
        if (/users\/[0-9]*/.test(Backbone.history.fragment)) {
          mm.router.navigate("/", { trigger: true, replace: true })
        } else if (/order\/[0-9]*/.test(Backbone.history.fragment)) {
          mm.router.navigate("/", { trigger: true, replace: true })
        } else {
          window.location.reload()
        }
        if ($link.hasClass("available")) {
          $link.removeClass("available")
          $("nav[role=main].desktop ol li").removeClass("consdensed")
        }
      })
    },

    onMixbench: function (payload) {
      mm.drawerMixbenchPage.show(payload)
      self._push()
    },
    offMixbench: function () {
      self._checkDelay(mm.drawerMixbenchPage.hide)
    },

    onMixtapes: function () {
      mm.drawerMixtapesPage.show()
      self._push()
    },
    offMixtapes: function () {
      self._checkDelay(mm.drawerMixtapesPage.hide)
    },

    onNav: function () {
      mm.drawerNavPage.show()
      self._push()
    },
    offNav: function () {
      self._checkDelay(mm.drawerNavPage.hide)
    },

    onProjectSearchbench: function (payload) {
      mm.drawerProjectSearchbenchPage.show(payload)
      self._push()
    },
    offProjectSearchbench: function () {
      self._checkDelay(mm.drawerProjectSearchbenchPage.hide)
    },

    onProjectSearches: function () {
      mm.drawerProjectSearchesPage.show()
      self._push()
    },
    offProjectSearches: function () {
      self._checkDelay(mm.drawerProjectSearchesPage.hide)
    },

    onRegister: function () {
      mm.drawerRegisterPage.show()
      self._push()
    },
    offRegister: function () {
      self._checkDelay(mm.drawerRegisterPage.hide)
    },

    onShare: function (payload) {
      mm.drawerSharePage.show(payload)
      self._push()
    },
    offShare: function () {
      self._checkDelay(mm.drawerSharePage.hide)
    },

    onActivation: function () {
      mm.drawerActivationPage.show()
      self._push()
    },

    offActivation: function () {
      self._checkDelay(mm.drawerActivationPage.hide)
    },

    onSubscribe: function () {
      mm.drawerNewsletterPage.show()
      self._push()
    },
    offSubscribe: function () {
      self._checkDelay(mm.drawerNewsletterPage.hide)
    },
  }

  mm.facade.on("refresh", self._render)
  mm.facade.on("desktop mobile", self.close)

  self.on("cart", function () {
    self.setState("cart")
  })

  self.on("cart:add", function (payload, remove) {
    remove = typeof remove === "undefined" ? false : remove
    self.setState("cartbench", payload, remove)
  })

  self.on("checkNav", function (payload) {
    self._current(payload)
  })

  self.on("close", function (callback) {
    callback = callback || undefined
    self.close(callback)
  })

  self.on("customize", function (payload) {
    self.setState("customize", payload)
  })

  self.on("mixtapes", function () {
    self.setState("mixtapes")
  })

  self.on("mixtape:add", function (payload) {
    if (!mm.user.isLoggedIn()) return false
    self.setState("mixbench", payload)
  })

  self.on("projectSearches", function () {
    self.setState("projectSearches")
  })

  self.on("projectSearch:add", function (payload) {
    if (!mm.user.isLoggedIn()) return false
    self.setState("projectSearchbench", payload)
  })

  self.on("register", function () {
    self.setState("register")
  })

  self.on("activation", function () {
    self.setState("activation")
  })

  self.on("login", function () {
    self.setState("login")
  })

  self.on("subscribe", function () {
    self.setState("subscribe")
  })

  self.on("share:mixtape", function (payload) {
    self.setState("share")
    mm.drawerSharePage.trigger("shareData", payload)
  })

  self.on("share:songVersion", function (payload) {
    self.setState("share")
    mm.drawerSharePage.trigger("shareData", payload)
  })

  return self
})()

mm.facade.on("app:ready", mm.drawer.init)
