/**
 * Base custom select drop-down
 * @constructor Functional
 * @public
 * @param  {$.Element} $el     The select from which to build
 * @param  {Array}     options A collection of options to parse
 * @return {Object}            An instance of mm.Selectable
 */
mm.Selectable = function ($el, options) {
  "use strict"

  if (typeof $el === "undefined") return

  var self = mm.EventEmitter(),
    _options = options || {}

  /**
   * This is the pseudo select markup.
   * Contains the prepped string to become an underscore template, should be
   * treated as a private prop; however, it can be overwritten if necessary.
   * @public
   * @type {String}
   */
  self._template =
    '<div class="sorter-wrap">' +
    '<a class="sorter-link" data-prevent-default>' +
    '<div class="selected-option"></div>' +
    '<div class="icon"><span></span></div>' +
    "</a>" +
    '<ol class="options">' +
    "<% _.each(options, function (option) { %>" +
    '<li class="option" data-value="<%= option.data %>">' +
    "<%= option.text %>" +
    "<% if (option.alt && option.alt.length) { %>" +
    "<span><%= option.alt %></span>" +
    "<% } %>" +
    "</li>" +
    "<% }); %>" +
    "</ol>" +
    "</div>"

  /**
   * The SELECT element to build from
   * @public
   * @type {$.Element}
   */
  self.$el = $el

  /**
   * The compiled template drop-down anchor, assigned in self.build
   * @public
   * @type {$.Element}
   */
  self.$link = undefined

  /**
   * The compiled template option elements, assigned in self.build
   * @public
   * @type {$.Element}
   */
  self.$options = undefined

  /**
   * The compiled template wrapper, assigned in self.build
   * @public
   * @type {$.Element}
   */
  self.$wrap = undefined

  /**
   * Is returned upon initialization, but can also be called to repopulate
   * select options.
   * @public
   * @param  {Array} opts Format: [{ text: '', data: '', alt: ''}]
   * @return {Obect}      This instance of mm.Selectable
   */
  self.build = function (opts) {
    if (typeof opts !== "undefined") {
      _options = opts
    }
    self.template = _.template(self._template, { options: _options })
    $el.after(self.template({ options: _options }))
    self.rebind()
    return self
  }

  /**
   * Resets/Rebinds variables with event handlers, called on self.build
   * @public
   */
  self.rebind = function () {
    self.$wrap = $el.next(".sorter-wrap")
    self.$link = $(".sorter-link", self.$wrap)
    self.$options = $(".option", self.$wrap)

    self.$link.off("click").on("click", function (e) {
      self.toggle(e, this)
    })

    self.$options.off("click").on("click", function (e) {
      self.select(e, this)
    })
  }

  /**
   * Event handler for when an option is clicked
   * @public
   * @param  {Object} e     Click event object
   * @param  {Element} that thisArg from click event
   */
  self.select = function (e, that) {
    if (e) {
      e.preventDefault()
    }
    that = $(that)
    var option = {
      html: that.html(),
      value: that.attr("data-value"),
    }
    self.selected(option)
    self.$wrap.removeClass("open")
  }

  /**
   * Handle a selected option, sample useage:
   *   var superSelected = self.selected;
   *   self.selected = function (opt) {
   *     superSelected(opt);
   *     mm.facade.trigger('youreventname', opt.value);
   *   };
   * @public
   * @param  {Object} opt The selected objected from self.select
   */
  self.selected = function (opt) {
    self.$options.removeClass("selected")
    $(".selected-option", self.$link).html(opt.html)
    self.$options.filter('[data-value="' + opt.value + '"]').addClass("selected")
    var $select = $("#order_billing_information_attributes_address_attributes_country")
    if ($select) $select.prepend($select.children().filter('[value="' + opt.value + '"]'))
    // trigger an event
    self.trigger("selected", opt)
  }

  /**
   * Toggles the drop-down open and closed
   * @public
   * @param  {Object}  e    Click event object
   * @param  {Element} that thisArg from click event
   */
  self.toggle = function (e, that) {
    e.preventDefault()
    e.stopImmediatePropagation()
    self.$wrap.toggleClass("open")
    if (self.$wrap.hasClass("open")) {
      $(document.body)
        .not(self.$wrap)
        .off("click")
        .on("click", function (evt) {
          evt.stopImmediatePropagation()
          self.$wrap.removeClass("open")
        })
    }
  }

  return self.build()
}
