/**
 * Represents a message to be display to the user via the mm.messageCenter modal
 * @constructor
 * @public
 * @param  {String} content The text/html to be displayed within the modal
 * @return {Object}         Instance of mm.Message
 */
mm.Message = function (content) {
  "use strict"

  // if there is no content to display, exit.
  if (typeof content === "undefined") return

  var self = {}

  /**
   * Publicize the content argument as a jquery object
   * @type {$.Element}
   */
  self.content = $(content)

  /**
   * Method to be called after message has been displayed; to be overwritten.
   * @public
   */
  self.callback = function () {}

  /**
   * Sends this message to the mm.messageCenter queue, this method must be
   * explicitly called for your message to ever be displayed.
   * @public
   */
  self.send = function () {
    mm.messageCenter.read(self)
  }

  return self
}
