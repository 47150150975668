/**
 * String formatter
 * @public
 * @extends {mm.EventEmitter}
 * @return {Object} Instance of mm.format
 */
mm.format = (function () {
  var self = mm.EventEmitter()

  /**
   * Converts a number to USD
   * @param  {Number} number Number convert
   * @return {String}        Number as currency
   */
  self.numberToCurrency = function (number) {
    var num = Number(number)
    return num.toLocaleString("en-US", { style: "currency", currency: "USD" })
  }

  return self
})()
